
const PriorityList = () => {
  const list = [
    { value: 1, label: 'Aktif Proje' },
    { value: 2, label: 'Pasif Proje' },
    { value: 3, label: 'Tamamlanan Proje' }
  ]
  return list;
}

export default PriorityList;