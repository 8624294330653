import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiClock, FiCalendar } from 'react-icons/fi'
import { Button } from '@nextui-org/react'


const ProjeComponentInsıde = ({ veri }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const images = [
    // "/assets/penaLogos/blue.svg",
    // "/assets/penaLogos/yellow.svg",
    // "/assets/penaLogos/orange.svg",
    // "/assets/penaLogos/purple.svg",
    // "/assets/penaLogos/turquoise.svg",
    // "/assets/penaLogos/yellow.svg",
    "/assets/penaLogos/red.svg",

  ];
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * images.length);
    const randomImage = images[randomIndex];
    setSelectedImage(randomImage);
  }, []);
  return (
    <div className="flex mt-2 transition-all duration-500 border-1 dark:border-dark-300 border-gray-200 bg-white dark:bg-dark-300 min-h-[50px] w-full rounded-xl">
      <Link to={'/projects/' + veri.pslug} className="w-full my-2 px-8">
        <div className="flex md:flex-row justify-between w-full">
          <div className="flex flex-row items-start  md:items-start md:gap-3">
            {/* <img
              className="w-16 h-12 rounded-lg object-contain"
              src={veri.pimg !== null ? process.env.REACT_APP_PROJECTIMG + veri.pimg + '.' + veri.pimgtype : process.env.REACT_APP_PROJECTIMG}
              alt={veri.title}
            /> */}
                   {selectedImage && (
                      <div className="">
                        <img
                          src={selectedImage}
                          alt="Selected"
                          className="w-16 h-20 rounded-full"
                          style={{ width: "65px", height: "65px" }}
                        />
                      </div>
                    )}
            <div className="flex flex-col items-start ml-3 mt-2">
              <span className="text-[#283252] dark:text-[#AAAAB3]  font-semibold md:text-[15px] text-xs">{veri.pname}</span>
              <span className="text-[#a2a5b9] md:text-[12px] text-xs">{veri.pfirm}</span>
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-3 md:gap-14 mt-3 md:mt-0">
            <div className="flex flex-col items-start justify-center gap-1">
              <div className="flex items-center">
                <FiCalendar className="mr-1 text-[#a2a5b9] text-sm" />
                <div className="text-[#a2a5b9] text-xs md:text-sm">{veri.updated_at.slice(0, 10)}</div>
              </div>
              {/* <div className="flex items-center">
                <FiClock className="mr-1 text-[#a2a5b9] text-sm" />
                <div className="text-[#a2a5b9] md:text-sm text-xs">{veri.updated_at_time}</div>
              </div> */}
            </div>

            <div className="flex items-center flex-col gap-4 md:flex-row md:gap-0 md:ml-4">
              <div className="bg-[#06D6A0] px-3 rounded-xl text-white text-[10px] py-1 md:ml-10 md:block hidden">Aktif</div>
            </div>

            <Button className="bg-white dark:bg-dark-300 text-black dark:text-[#a2a5b9] text-xs h-12 w-28 md:block hidden">Görüntüle</Button>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ProjeComponentInsıde
