import React from "react";
import { FaTimes } from "react-icons/fa";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { postProjectsUpdate, postProjectsDelete } from "services/projects";
const EditPopupComponent = ({ projectsData, closePopup, onUpdate, show }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [pname, setPname] = useState("");
  const [pfirm, setPfirm] = useState("");
  const [ptools, setTools] = useState(0);
  const [pimg, setPimg] = useState("");
  const [pimgtype, setPimgtype] = useState("");
  const [penddate, setPenddate] = useState(0);
  const { id } = useParams();

  const handleClose = () => {
    closePopup(false);
  };
  const handleDelete = async () => {
    const response = await postProjectsDelete(id);
    navigate("/projects");
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      closePopup(false);
    }
  };

  async function handleUpdate() {
    const data = {
      slug: id,
      pname,
      pfirm,
      pimgtype,
      ptools,
      pimg,
      penddate,
    };
    const response = await postProjectsUpdate(data);
    window.location.reload();
  }

  return (
    <>
      {show && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 "
          onClick={handleBackgroundClick}
        >
          <div className="bg-white w-[800px] h-[500px] dark:bg-dark-300 rounded-md z-50 overflow-y-auto overflow-x-hidden">
            <div className="space-y-4 ">
              <div className=" p-4 ">
                <h1 className="font-bold text-gray-39 dark:text-gray-300 text-sm">
                  Projeyi Düzenle
                </h1>
              </div>

              <div className="w-full mt-4 border-t border-zinc-300"></div>
              {/* görsel eklentisinden sonra düzenlenmeli */}
              {/* <div className="flex p-4">
                <h4 className="text-sm opacity-60 w-2/4 mt-8 ">
                  Güncel Görsel
                </h4>
                <div className=" text-gray-39 w-4/6 text-sm mt-4 border-dashed  border-stone-300 rounded-md border-[3px] ">
                  <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
                    {projectsData.pimg !== null ? (
                      <div
                        className="avatar border-[1px]"
                        src={
                          process.env.NEXT_PUBLIC_USERIMG +
                          projectsData.pimg +
                          "." +
                          projectsData.pimgtype
                        }
                      />
                    ) : (
                      <input
                        type="text"
                        placeholder="Type here"
                        className=" h-[80px] bg-stone-200 input input-bordered w-full"
                      />
                    )}
                  </div>
                </div>
              </div> */}
              <div className="p-4 space-y-4 dark:text-gray-300 text-gray-39 rounded-lg">
                <h4 className="text-sm opacity-60">Proje Adı</h4>
                <div className=" ">
                  <input
                    className="w-[750px] bg-white text-black border-gray-100 dark:bg-gray-800 dark:text-gray-50 dark:border-gray-500 p-2 h-[40px] text-sm"
                    type="text"
                    placeholder="Type here"
                    onChange={(e) => setPname(e.target.value)}
                    defaultValue={projectsData.pname}
                  />
                </div>

                <div className="flex gap-3 w-2/4 ">
                  <div>
                    <h4 className="text-sm opacity-60 ">Proje Firması</h4>
                    <div className="flex  flex-wrap md:flex-nowrap gap-4">
                      <input
                        type="text"
                        className="p-2 my-2  bg-white text-black border-gray-100 dark:bg-gray-800 dark:text-gray-50 dark:border-gray-500"
                        placeholder="Type here"
                        onChange={(e) => setPfirm(e.target.value)}
                        defaultValue={projectsData.pfirm}
                      />
                    </div>
                  </div>
                  <div>
                    <h4 className="text-sm opacity-60">Proje Müşterisi</h4>
                    <div className="flex  flex-wrap md:flex-nowrap gap-4">
                      <input
                        type="text"
                        className="p-2 my-2  bg-white text-black border-gray-100 dark:bg-gray-800 dark:text-gray-50 dark:border-gray-500"
                        placeholder="Type here"
                        defaultValue={projectsData.pcustomer}
                      />
                    </div>
                  </div>
                  <div>
                    <h4 className="text-sm opacity-60">Tahmini Bitiş Tarihi</h4>
                    <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
                      <input
                        type="text"
                        className="p-2 my-2  bg-white text-black border-gray-100 dark:bg-gray-800 dark:text-gray-50 dark:border-gray-500"
                        placeholder="Type here"
                        defaultValue={projectsData.penddate}
                        onChange={(e) => setPenddate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <h4 className="text-sm opacity-60">Proje Alanı</h4>
                    <div className="flex w-full  flex-wrap md:flex-nowrap gap-4">
                      <input
                        className="p-2 my-2 bg-white text-black border-gray-100 dark:bg-gray-800 dark:text-gray-50 dark:border-gray-500"
                        type="text"
                        label=""
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <h4 className="text-sm opacity-60">Proje Bütçesi</h4>
                  <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
                    <input
                      className="p-2 my-2 bg-white text-black border-gray-100 dark:bg-gray-800 dark:text-gray-50 dark:border-gray-500"
                      type="text"
                      placeholder="Type here"
                      defaultValue={projectsData.pbudget}
                    />
                  </div>
                </div>
              </div>
              <div className="p-4">
                <h4 className="text-sm opacity-60">Açıklama</h4>
                <div className="flex flex-wrap md:flex-nowrap gap-4">
                  <input
                    type="text"
                    className=" bg-white text-black border-gray-100 dark:bg-gray-800 dark:text-gray-50 dark:border-gray-500 p-2 my-2"
                    placeholder="Type here"
                    defaultValue={projectsData.pdesc}
                  />
                </div>
              </div>
              <div className="space-y-4 mt-4 rounded-lg p-4 ">
                {/* ilgili düzenlemeler için backend tarafında düzenleme yapılmalı */}

                {/* <div className="w-full h-[140px] bg-cyan-50 border-2 border-cyan-300 p-6">
                  <p className="opacity-50 text-md">
                    Projeyi tamamlandı olarak işaretlediğiniz zaman hiçbir
                    işlemi kullanamayacaksınız. Tekrardan aktif edene kadar tüm
                    işlemler kısıtlanacaktır.
                  </p>
                  <div>
                    <button
                      className="btn border-[#039be5] mt-2 text-[#039be5] hover:bg-cyan-300"
                      color="primary"
                      variant="ghost"
                    >
                      <p> Tamamlandı olarak işaretle!</p>
                      <FaTimes size={18} />
                    </button>
                  </div>
                </div>

                <div className="w-full h-[140px] bg-orange-50 border-2 border-orange-300 p-6">
                  <p className="opacity-50 text-md">
                    Projeyi deaktif ettiğiniz zaman hiçbir işlemi
                    kullanamayacaksınız. Tekrardan aktif edene kadar tüm
                    işlemler kısıtlanacaktır.
                  </p>
                  <div className="">
                    <button
                      className="btn border-[#faae42] mt-2 text-[#faae42] hover:bg-orange-300"
                      color="primary"
                      variant="ghost"
                    >
                      <p>Deaktif et</p>
                      <FaTimes size={18} />
                    </button>
                  </div>
                </div>  */}

                <div className="w-full h-[160px] dark:bg-gray-900 bg-rose-50 border-2 dark:border-rose-900 border-rose-300 p-6">
                  <h3 className="text-red-800 font-bold">DİKKAT!</h3>
                  <p className="opacity-50 text-md">
                    Proje silme işlemini onaylamanız halinde, proje içerisinde
                    bulunan tüm içerikler, kullanıcılar, görevler ve kayıtlı
                    olan tüm dökümanlar kalıcı olarak silinmektedir.
                  </p>
                  <div className="pb-5">
                    <button
                      className="btn mb-5 border-[#e62965] mt-2 text-[#e62965] border-1 dark:hover:bg-gray-600 hover:bg-rose-300"
                      color="primary"
                      variant="ghost"
                      onClick={() => handleDelete()}
                    >
                      <p> Kalıcı olarak sil</p>
                      <FaTimes size={18} />
                    </button>
                  </div>
                </div>
              </div>

              <div className="w-full border-t border-zinc-300"></div>
              <div className="flex justify-end m-2 ">
                <button
                  className="btn dark:text-gray-400 text-black"
                  color="primary"
                  variant="ghost"
                >
                  Çıkış
                </button>
                <button
                  className="btn ml-2"
                  color="danger"
                  onClick={() => handleUpdate()}
                >
                  Güncelle
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditPopupComponent;
