import React from "react";

export default function LandingMainC() {
  return (
    <div className="mx-auto px-4 mt-16">
      <div className="flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2">
          <img
            src="../../assets/landing/penatel.png"
            alt="LandingMainC"
            className="w-full h-auto"
          />
        </div>
        <div className="lg:w-1/2 mt-8 ml-4 lg:mt-0 lg:pl-8">
          <div className="max-w-md mx-auto lg:mx-0">
            <h3 className="font-semibold text-gray-800 text-xl mb-2">
              Her Platformda Etkileşim
            </h3>
            <p className="text-gray-600 text-base mb-5">
              Sadece web uygulaması değil aynı zamanda mobil uygulaması ile
              projelerinizi takip edin!
            </p>
            <h3 className="font-semibold text-gray-800 text-xl mb-2">
              Basit ve anlaşılır arayüz
            </h3>
            <p className="text-gray-600 text-base mb-5">
              Pena basit ve kullanıcı dostu arayüzü ile aradığınız her şeyi
              kolayca bulmanızda yardımcı olur.
            </p>
            <h3 className="font-semibold text-gray-800 text-xl mb-2">
              Etkili Proje Yönetim Sistemi
            </h3>
            <p className="text-gray-600 text-base">
              Projelerinizi oluşturun, ekibinizi dahil edin ve görevler ekleyin.
              İşte hepsi bu kadar basit!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
