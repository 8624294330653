import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { useParams } from "react-router-dom"; // useParams'ı import ediyoruz
import { postProjectsUserSet } from "services/projects";

function ChangePopup(props) {
  const [isOpen, setIsOpen] = useState(true);
  const [action, setAction] = useState(1);
  const { id } = useParams(); // URL'den projectId parametresini alıyoruz
  const data = { slug: id };

  const handleClose = () => {
    setIsOpen(false);
    props.closePopup(false);
  };
  const handleAdmin = async () => {
    const metadata = {
      slug: data.slug,
      userid: props.member.userId,
      action: 1,
    };
    const response = await postProjectsUserSet(metadata);
    handleClose();
  };
  const handleUser = async () => {
    const metadata = {
      slug: data.slug,
      userid: props.member.userId,
      action: 11,
    };
    const response = await postProjectsUserSet(metadata);
    handleClose();
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      // Eğer overlay alanına tıklanırsa kapat
      setIsOpen(false);
      props.closePopup(false);
    }
  };

  return (
    <>
      {props.show && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleBackgroundClick}
        >
          <div className="bg-white w-[250px] h-[150px] rounded-md shadow-lg">
            <div className="flex items-center justify-between p-4 border-b border-gray-200">
              <h1 className="text-sm font-bold text-gray-700">
                Kullanıcı Yetkilendirmesi{" "}
              </h1>
              <button onClick={handleClose}>
                <MdClose className="text-xl text-black" />
              </button>
            </div>
            <div className="p-4">
              {props.member.pstatus === 11 && (
                <button
                  className="btn w-full bg-red-500 text-white "
                  onClick={() => handleAdmin()}
                >
                  Kullanıcının Yetkisini Kaldır
                </button>
              )}
              {props.member.pstatus !== 11 && (
                <button
                  className="btn w-full bg-red-500 text-white"
                  onClick={() => handleUser()}
                >
                  Kullanıcıya Yetki Ver
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChangePopup;
