import React from 'react';
import RegisterComponent from './components/registerComponent';


export default function Register() {
    return (
        <div className="w-full dark:bg-dark-100  bg-stone-100 ">
        <div className="">
            <RegisterComponent />
        </div>
        </div>
    );
    }
