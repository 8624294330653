import LoginComponent from "./components/loginComponent.js";
import React from "react";

export default function Login() {
    return (
        <div className="w-full dark:bg-dark-100  bg-stone-100 ">
        <div>
            <LoginComponent/>
        </div>
        </div>
    );
}
