import { Link } from 'react-router-dom'
import React from 'react'
import CircularProgressComponent from '../../../../mainComponents/circularProgressComponent'
import { FiClock, FiCalendar } from 'react-icons/fi'


const ActivComponentInsıde = ({ item, isProject }) => {
  //ToDO:: Buraya isProject ile kontrol ekleyip görev mi proje mi karar ver görevse progress component ekle burada itemda görev için gelen değerleri incele

  return (
    <Link to={isProject ? '/projects/' + item.pslug : ''} className="flex justify-between mt-5 mb-4 px-8">
      <div className="flex flex-row gap-5 items-center">
         <div className="h-avatar is-medium">
          {isProject ? (
            <div className="avatar">
            <div className="w-12 rounded-full">
              <img
                          src= "/assets/penaLogos/red.svg"
                          alt="Selected"
                        />
              {/* <img src={item.pimg !== null ? process.env.NEXT_PUBLIC_PROJECTIMG + item.pimg + '.' + item.pimgtype : process.env.NEXT_PUBLIC_USERIMG_BLANK} /> */}
            </div>
          </div>
          ) : (
            <CircularProgressComponent items={{ priority: item.c_priority, width: '32px', height: '32px' }} />
          )}
        </div>
        
        <div className="flex flex-col pt-[5px]">
          <span className="text-[#283252] dark:text-[#AAAAB3] font-bold text-[15px] ">{isProject ? item.pname : item.c_title}</span>
          <span className="text-[#a2a5b9] text-[12px]">{isProject ? item.pfirm : item.c_desc}</span>
        </div>
      </div>
      <div className="flex-end dark:text-darkWhite-300">
        <div className="dark-inverted text-[12px]">
          <div className="flex pb-1 items-center gap-1">
            <FiCalendar /> <p>{isProject ? item.updated_at.slice(0, 10) : item.c_end} </p>
          </div>
          <div className="flex items-center gap-1">
            <FiClock /> <p>{isProject ? item.updated_at_time : item.c_start}</p>
          </div>
        </div>
      </div>
    </Link>
  )
}
export default ActivComponentInsıde
