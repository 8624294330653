import { useLocation } from "react-router-dom";
import HeaderComponent from "mainComponents/headerComponent";
import { PiCircleBold } from "react-icons/pi";

import MenuBarComponent from "../../components/MenuBarComponent";
export default function ProjectsTimelineInside() {
  const updateInfo = {
    0: "oluşturdu.",
    1: "işleme aldı.",
    2: "hazır hale getirdi.",
    3: "incelemeye aldı.",
    4: "tamamladı.",
  };
  const location = useLocation();
  const data = location.state;
  return (
    <div className=" w-full min-h-screen dark:bg-dark-100  bg-stone-100 transition duration-300 ">
      <div className="container mx-auto">
        <HeaderComponent title={"Proje Süreci"} />
        <MenuBarComponent title={"timeline"} />
        <div className="flex w-full mt-5">
          <ul className="timeline timeline-vertical w-full -ml-[100px]  ">
            {data.date.map((timeline, index) => (
              <li className="grid grid-cols-9 justify-center">
                <div className="timeline-start dark:text-gray-500 -mr-10">
                  {timeline.slice(0, 10)}
                </div>
                <div className="timeline-middle">
                  <div className="text-blue-600">
                    <PiCircleBold />
                  </div>
                </div>{" "}
                <hr className="bg-gray-200 dark:bg-gray-500 " />{" "}
                <div className="col-span-7 -ml-10 timeline-end dark:bg-dark-300 dark:border-gray-500 bg-white border-gray-200 timeline-box rounded-md flex justify-between items-center min-h-20 gap-3 w-[1125px]">
                {" "}
                  <div className="flex items-center gap-3">
                    <div className="text-red-600 ">
                      <PiCircleBold size={20} />
                    </div>
                    <div className="avatar"></div>
                    <div className="flex flex-col">
                      <div>
                      <span className="font-bold text-black dark:text-gray-400">{data.name[index]}</span>
                        <span className=" text-gray-500">
                          {" "}
                          {data.tsubject} görevini{" "}
                        </span>
                        <span className="text-purple-500">
                          {data.tstatus[index] == data.tstatus[index + 1] ? (
                            <span>güncelledi.</span>
                          ) : (
                            updateInfo[data.tstatus[index]]
                          )}
                        </span>
                      </div>
                      <div className="text-xs text-gray-500">
                        {" "}
                        {timeline.slice(11, 16)}
                      </div>
                    </div>
                  </div>
                  {/* list */}{" "}
                </div>{" "}
                <hr className="bg-gray-200 dark:bg-gray-500 " />{" "}
                </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
