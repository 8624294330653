import React from 'react'
import { Link } from 'react-router-dom'
import { SiHackthebox } from 'react-icons/si'
import { FiUsers } from 'react-icons/fi'
import { FaRegClock, FaTasks } from 'react-icons/fa'
import { RiBarChartBoxLine } from 'react-icons/ri'
import { useParams } from 'react-router-dom'

export default function MenuBarComponent(props) {
  const path = useParams()

  return (
    <div className="flex">
      <div className="border dark:border-dark-300 bg-white dark:bg-dark-300  w-full">
        <div className={`w-full flex justify-between px-[10px] pt-2 rounded-sm`}>
          <Link to={`/projects/${path.id}`} className={`w-full pb-[6px] ${props.title == 'prodash' ? 'border-b-2 border-red-600 dark:border-[#797BF2]' : ''}`}>
            <div className={`flex justify-center hover:text-red-600 dark:hover:text-[#797BF2]  text-sm font-semibold ${props.title == 'prodash' ?'text-red-600 dark:text-[#797BF2]' : 'text-gray-600 dark:text-[#A6A1A1]'} `}>
              <SiHackthebox className="mr-2 mt-[3px] text-[14px]" />
              Panel
            </div>
          </Link>

          <Link to={`/projects/${path.id}/team`} className={`w-full ${props.title == 'team' ? 'border-b-2 border-red-600 dark:border-[#797BF2]' : ''}`}>
            <div className={`flex justify-center hover:text-red-600 dark:hover:text-[#797BF2]    text-sm font-semibold ${props.title == 'team' ?'text-red-600 dark:text-[#797BF2]' : 'text-gray-600 dark:text-[#A6A1A1]'}`}>
              <FiUsers className="mr-2 mt-[3px] text-[14px]" />
              Ekip
            </div>
          </Link>

          <Link to={`/projects/${path.id}/timeline`} className={`w-full ${props.title == 'timeline' ? 'border-b-2 border-red-600 dark:border-[#797BF2]' : ''}`}>
            <div className={`flex justify-center hover:text-red-600 dark:hover:text-[#797BF2]   text-sm font-semibold ${props.title == 'timeline' ?'text-red-600 dark:text-[#797BF2]' : 'text-gray-600 dark:text-[#A6A1A1]'}`}>
              <FaRegClock className="mr-2 mt-[3px] text-[14px]" />
              Süreç
            </div>
          </Link>

          <Link to={`/projects/${path.id}/tasks`} className={`w-full ${props.title == 'tasks' ? 'border-b-2 border-red-600 dark:border-[#797BF2]' : ''}`}>
            <div className={`flex justify-center hover:text-red-600 dark:hover:text-[#797BF2]  text-sm font-semibold ${props.title == 'tasks' ?'text-red-600 dark:text-[#797BF2]' : 'text-gray-600 dark:text-[#A6A1A1]'}`}>
              <FaTasks className="mr-2 mt-[3px] text-[14px]" />
              Görevler
            </div>
          </Link>

          <Link to={`/projects/${path.id}/kanban`} className={`w-full ${props.title == 'kanban' ? 'border-b-2 border-red-700' : 'text-gray-600'}`}>
            <div className={`flex justify-center hover:text-red-600 dark:hover:text-[#797BF2]  text-sm font-semibold ${props.title == 'kanban' ?'text-red-600 dark:text-[#797BF2]' : 'text-gray-600 dark:text-[#A6A1A1]'}`}>
              <RiBarChartBoxLine className="mr-2 mt-[3px] text-[14px]" />
              Kanban
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
