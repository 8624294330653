import React, { useState, useEffect } from "react";
import { FiSun, FiMoon } from "react-icons/fi";

const ThemeComponent = () => {
  const [mounted, setMounted] = useState(false);
 const [theme, setTheme] = useState();

  function checkTheme(){
    if (localStorage.theme != 'dark') {
      document.documentElement.classList.add('dark')
      document.documentElement.classList.remove('light')
      setTheme("dark")
      localStorage.theme = 'dark'
    } else {
      document.documentElement.classList.remove('dark')
      document.documentElement.classList.add('light')
      setTheme("light")
      localStorage.theme = 'light'
    }
  }
  
  return (
    <div className="flex mr-3">
      {theme === "light" ? (
        <FiSun
          onClick={() => checkTheme()}
          className="cursor-pointer"
          size={26}
          fill="#ffb62e"
          color="#ffb62e"
        />
      ) : (
        <FiMoon
          onClick={() => checkTheme()}
          className="cursor-pointer"
          size={26}
          fill="#ffb62e"
          color="#ffb62e"
        />
      )}
    </div>
  );
};

export default ThemeComponent;
