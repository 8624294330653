 import LandingHeader from 'pages/landing/components/LandingHeader/'
 import LandingMainA from './components/LandingMainA/index'
 import LandingMainB from './components/LandingMainB/index'
 import LandingMainC from './components/LandingMainC/index'
 import LandingMainD from './components/LandingMainD/index'
 import LandingFooter from './components/LandingFooter/index'

export default function LandingPage() {
  return (
    <div className="bg-no-repeat lg:bg-cover bg-contain bg-top">
      <div className="space-y-24 h-auto">
         <LandingHeader />
        <LandingMainA />
        <LandingMainB />
        <LandingMainC />
        <LandingMainD />
        <LandingFooter /> 
      </div>
    </div>
  )
}
