import axiosClient from '../libs/axios'


export const getProjects = async () => {
  const response = await axiosClient.get('/projects')
  return response.data
}

export const postProjectsCreate = async (data) => {
  const response = await axiosClient.post('/projects/create', data)
  return response.data
}

export const getProjectsSearch = async (data) => {
  const response = await axiosClient.get('/projects/search/' + data.slug)
  return response.data
}

export const getProjectsTiny = async (data) => {
  const response = await axiosClient.get('/projects/tiny/' + data.slug)
  return response.data
}

export const getProjectsDash = async (data) => {
  const response = await axiosClient.get('/projects/dash/' + data.slug)
  return response.data
}
export const getProjectsTeam = async (data) => {
  const response = await axiosClient.get('/projects/team/' + data.slug)
  return response.data
}

export const getProjectsTeamInvite = async (data) => {
  const response = await axiosClient.get('/projects/teaminvite/' + data.slug)
  return response.data
}

export const getProjectsTimeline = async (data) => {
  const response = await axiosClient.get('/projects/timeline/' + data.slug)
  return response.data
}

export const postProjectsTimelineSet = async (data) => {
  const response = await axiosClient.post('/projects/timeline/set/' + data.slug, data)
  return response.data
}
export const postProjectsUserSet = async (data) => {
  const response = await axiosClient.post('/projects/user/set/' + data.slug, data)
  return response.data
}

export const postProjectsStatusSet = async (data) => {
  const response = await axiosClient.post('/projects/status/set/' + data.slug, data)
  return response.data
}
export const postProjectsUserDelete = async (data) => {
  const response = await axiosClient.delete(`/projects/user/delete/${data.slug}?id=` + data.id)
  return response.data
}
export const postProjectsDelete = async (slug) => {
  const response = await axiosClient.delete('/projects/delete/' + slug)
 return response.data
}

export const postProjectsUserJointo = async (data) => {
  const response = await axiosClient.post('/projects/user/join/' + data.slug, data)
  return response.data
}

export const postProjectsUpdate = async (data) => {
  const response = await axiosClient.post('/projects/update/' + data.slug, data)
  return response.data
}

export const postProjectTask = async (slug,data) => {
  const response = await axiosClient.get('/projects/task/' + slug + "?taskId=" + data)
  return response.data
}

export const postProjectsTaskDelete = async (data ) => {
  const response = await axiosClient.post('/projects/task/delete/' + data.slug, data)
  return response.data
}
export const postProjectsTaskNew = async (slug,data) => {
  const response = await axiosClient.post('/projects/task/new/' + slug, data)
  return response.data
}
export const postProjectsTaskEdit = async (slug,data) => {
  const response = await axiosClient.post('/projects/task/edit/' + slug, data)
  return response.data
}
export const postProjectsTaskStatus = async (data) => {
  const response = await axiosClient.post('/projects/task/status/' + data.slug, data)
  return response.data
}
export const getProjectsTasks = async (data) => {
  const response = await axiosClient.get('/projects/tasks/' + data.slug)
  return response.data 
}
export const postProjectsTasksByStatus = async (data) => {
  const response = await axiosClient.post('/projects/tasks/status/' + data.slug, data)
  return response.data
}
export const postProjectsTasksByPriority = async (data) => {
  const response = await axiosClient.post('/projects/tasks/priority/' + data.slug, data)
  return response.data
}
export const postProjectsNotifyAndUpdate = async (data) => {
  const response = await axiosClient.post('/projects/notifyandupdate/', data)
  return response.data
}
