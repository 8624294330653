
import React from 'react'
import { FaCalendarAlt } from 'react-icons/fa'
import CircularProgressComponent from 'mainComponents/circularProgressComponent'



const GorevlerCardComponent = (props) => {
  const { title, date, priority, isPrivate, status, member, onClick } = props
  const getPriotityText = (priority) => {
    switch (priority) {
      case 1:
        return 'Çok Düşük'
      case 2:
        return 'Düşük'
      case 3:
        return 'Normal'
      case 4:
        return 'Yüksek'
      case 5:
        return 'Çok Yüksek'
      default:
        return ''
    }
  }

  const priorityText = getPriotityText(priority)

  return (
    <div
      className={`relative hover:translate-y-[-5px] dark:text-gray-400 cursor-pointer transition-all hover:duration-500 hover:shadow-md rounded-[6px] w-full h-[200px] ${
        status === 'Tamamlandı' ? 'bg-[#ECECEC] dark:bg-[#1D1D1D]' : 'dark:bg-dark-300 bg-white'
      }`}
      onClick={isPrivate === false && onClick}
    >
      <h2 className="pt-5 ml-8 text-base font-semibold max-w-80 break-words whitespace-normal"> {title.slice(0, 70)}</h2>

      {isPrivate ? (
        priorityText && <p className="ml-8 text-xs font-light text-[#999] mt-1">Öncelik: {priorityText}</p>
      ) : (
        <p className="ml-8 text-xs font-light text-[#999] mt-1">{status}</p>
      )}
      <div className="absolute flex flex-col items-start gap-3 ml-4 left-4 bottom-5">
        {isPrivate === false && (
          <div className={`flex flex-row items-center gap-2 ${isPrivate ? 'hidden' : 'block'}`}>
            
             <img
              src={member.member_img == null ? "/assets/penaLogos/red.svg" : "/assets/penaLogos/red.svg"}
              className=" avatar dark:border-0 border-[1px] h-6 w-6 rounded-full"
            />  
            <p>{member.member_name}</p>
          </div>
        )}

        <div className="flex flex-row items-center">
          <FaCalendarAlt className="text-sm text-gray-600 dark:text-gray-400" />
          <p className="ml-2 text-xs text-gray-500 dark:text-gray-400 ">{date.slice(0,10)}</p>
        </div>
      </div>

      <div className="absolute flex flex-col items-center gap-2 bottom-6 right-4">
        <CircularProgressComponent items={{ priority, width: '40px', height: '40px' }} />
        {isPrivate === false && <p className="text-xs font-light text-[#999] mt-1">{priorityText}</p>}
      </div>
    </div>
  )
}

export default GorevlerCardComponent
