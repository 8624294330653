import React from 'react'
import ProjeComponentInsıde from './inside'


const ProjectList = ({ data }) => {
  return (
    <div className=" flex flex-col ">
      {data.length > 0 ? (
        data.map((item) => (
          <ProjeComponentInsıde key={item.id} veri={item} />
        ))
      ) : (
        <div className="flex justify-between mt-7 border-1 dark:border-dark-300  border-gray-200  bg-white  dark:bg-dark-300   min-h-[400px] w-full">
          <div className="w-full">
            <div className="flex  items-center flex-col">
              <h1 className=" text-black dark:text-darkWhite-300 text-lg font-bold">Herhangi bir proje bulamadık.</h1>
              <p className="opacity-50 text-center text-sm w-[500px] ">Görünüşe göre herhangi bir projede yer almıyorsunuz veya sahibi değilsiniz. Hemen yenisini oluşturabilir veya katılabilirsiniz.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProjectList
