import React from 'react'
import { useState } from 'react'

import { Tabs, Tab, Card, CardBody } from '@nextui-org/react'
import { Skeleton } from '@nextui-org/react'

function ThemePopupComponent(props) {
  const [isOpen, setIsOpen] = useState(true)

  const handleClose = () => {
    props.closePopup(false)
  }

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      // Eğer overlay alanına tıklanırsa kapat
      props.closePopup(false)
    }
  }
  return (
    <>
      {props.show && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 " onClick={handleBackgroundClick}>
          <div className="bg-white w-[800px] h-[550px] rounded-md shadow-lg">
            <div className="flex justify-between items-center border-b border-gray-200 p-4"></div>
            <div className="flex w-full h-[300px] flex-col p-3">
              <Tabs aria-label="Options">
                <Tab key="Defaulttheme" title="Default theme">
                  <Card className="w-full h-[300px]">
                    <CardBody>
                      <Card className="w-[200px] space-y-5 p-4" radius="lg">
                        <Skeleton className="rounded-lg">
                          <div className="h-24 rounded-lg bg-default-300"></div>
                        </Skeleton>
                        <div className="space-y-3">
                          <Skeleton className="w-3/5 rounded-lg">
                            <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                          </Skeleton>
                          <Skeleton className="w-4/5 rounded-lg">
                            <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                          </Skeleton>
                          <Skeleton className="w-2/5 rounded-lg">
                            <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                          </Skeleton>
                        </div>
                      </Card>
                    </CardBody>
                  </Card>
                </Tab>
                <Tab key="Fadetheme" title="Fade theme">
                  <Card className="w-full h-[300px]">
                    <CardBody>
                      <Card className="w-[200px] space-y-5 p-4" radius="lg">
                        <Skeleton className="rounded-lg">
                          <div className="h-24 rounded-lg bg-default-300"></div>
                        </Skeleton>
                        <div className="space-y-3">
                          <Skeleton className="w-3/5 rounded-lg">
                            <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                          </Skeleton>
                          <Skeleton className="w-4/5 rounded-lg">
                            <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                          </Skeleton>
                          <Skeleton className="w-2/5 rounded-lg">
                            <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                          </Skeleton>
                        </div>
                      </Card>
                    </CardBody>
                  </Card>
                </Tab>
                <Tab key="coloredtheme" title="Color theme">
                  <Card className="w-full h-[300px]">
                    <CardBody>
                      <Card className="w-[200px] space-y-5 p-4" radius="lg">
                        <Skeleton className="rounded-lg">
                          <div className="h-24 rounded-lg bg-default-300"></div>
                        </Skeleton>
                        <div className="space-y-3">
                          <Skeleton className="w-3/5 rounded-lg">
                            <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                          </Skeleton>
                          <Skeleton className="w-4/5 rounded-lg">
                            <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                          </Skeleton>
                          <Skeleton className="w-2/5 rounded-lg">
                            <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                          </Skeleton>
                        </div>
                      </Card>
                    </CardBody>
                  </Card>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ThemePopupComponent
