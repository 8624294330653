import React from 'react'

export default function LandingFooter() {
  return (
    <footer className="container mx-auto px-4 mt-20 lg:mt-44">
      <div className="flex flex-col lg:flex-row justify-between gap-10 lg:gap-20">
        <div className="lg:max-w-sm">
          <p className="text-sm lg:text-base mb-5">
            Hey Pena, projelerini geliştirirken süreç takibinde sorun yaşayan ekipler ve güncellemeleri takip etmek isteyen herkes için geliştirildi.
          </p>
          <p className="text-sm">A Project of Brave</p>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-10 lg:gap-20">
          {[
            {
              title: "PENA",
              items: ["Giriş", "Özellikler", "Araçlar"]
            },
            {
              title: "YOL HARİTASI",
              items: ["Giriş Yap", "Kayıt Ol", "Nasıl Kullanırım?", "İletişim"]
            },
            {
              title: "EKSTRA",
              items: ["KVKK Metni", "Gizlilik Sözleşmesi", "Cookie Kullanımı"]
            }
          ].map((section, index) => (
            <div key={index}>
              <h3 className="text-lg font-semibold text-gray-800 mb-4">{section.title}</h3>
              <ul className="space-y-2">
                {section.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="text-sm text-gray-600 hover:text-gray-800 cursor-pointer">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      
      <p className="text-zinc-400 text-center mt-16 text-sm py-4">
        @2024 | Hey Pena, All rights reserved
      </p>
    </footer>
  )
}