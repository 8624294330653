import React from 'react'

export default function LandingMainD() {
  return (
    <div className="container mx-auto px-4 mt-16">
      <div className="flex flex-col lg:flex-row items-center">
        <div className="lg:w-2/3 order-2 lg:order-1">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-800 mb-4">Hey Pena Nedir?</h2>
          <div className="max-w-2xl">
            <p className="text-gray-600 text-lg mb-4">
              Hey Pena, ekiplere ve bireylere projelerini kolaylıkla planlama, organize etme ve yürütme yetkisi veren son teknoloji bir çevrimiçi platformdur.
            </p>
            <p className="text-gray-600 text-lg">
              Kullanıcı dostu bir arayüzü, her boyuttaki veya karmaşıklıktaki projelere uyacak şekilde uyarlanabilen güçlü özellikler ve araçlarla birleştiren, tüm proje yönetimi
              ihtiyaçlarınız için tek adrestir.
            </p>
          </div>
        </div>
        <div className="lg:w-1/3 order-1 lg:order-2 mb-8 lg:mb-0">
          <img src="../../assets/landing/manpho.png" alt="LandingMainC" className="w-full h-auto" />
        </div>
      </div>
    </div>
  )
}