import React, { useState } from "react";
import { FiMail, FiLock, FiUser } from "react-icons/fi";
import { register } from "../api/auth";
import Notiflix from "notiflix";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

//ToDo: buradaki inputlar daisy input ile değiştirilcek.
function RegisterComponent() {
  const [ufname, setUfname] = useState("");
  const [ulname, setUlname] = useState("");
  const [umail, setUmail] = useState("");
  const [upass, setUpass] = useState("");
  const [uwork, setUwork] = useState("");
  const [uimg, setUimg] = useState("");
  const navigate = useNavigate();
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleRegister();
    }
  };
  const handleRegister = async () => {
    const response = await register({
      name: ufname,
      ulname: ulname,
      email: umail,
      password: upass,
      uimg: uimg,
      uwork: uwork,
    });
    Notiflix.Notify.success("Kayıt Başarılı");
    navigate("/login");
  };

  return (
    <div className="h-screen flex justify-center bg-[#fdfdfd]">
      <div className="flex w-1/3 justify-end items-center bg-white">
        <form className="bg-white ">
          <h1 className="text-red-600 text-3xl ">Kayıt Ol</h1>
          <p className="text-sm font-normal text-gray-600 mb-10 my-4">
            Kayıt olmak için formu kullanabilirsin.
          </p>
          <label className="input input-bordered flex w-80 h-[60px] items-center  py-2 px-3 rounded-2xl mb-5">
            <FiUser className="h-5 w-5 text-gray-400" />
            <input
              className="pl-2 max-w-xs"
              onChange={(e) => setUfname(e.target.value)}
              value={ufname}
              type="text"
              name="ufname"
              id=""
              placeholder="Adınız"
            />
          </label>
          <label className=" input input-bordered flex w-80 h-[60px] items-center py-2 px-3 rounded-2xl mb-5">
            <FiUser className="h-5 w-5 text-gray-400" />
            <input
              className="pl-2 max-w-xs"
              onChange={(e) => setUlname(e.target.value)}
              value={ulname}
              type="text"
              name="ulname"
              id=""
              placeholder="Soyadınız"
            />
          </label>
          <label className=" input input-bordered flex w-80 h-[60px] items-center  py-2 px-3 rounded-2xl mb-5">
            <FiMail className="h-5 w-5 text-gray-400" />
            <input
              className="pl-2 max-w-xs"
              onChange={(e) => setUmail(e.target.value)}
              value={umail}
              type="text"
              name="umail"
              id=""
              placeholder="E-Posta"
            />
          </label>
          <label className="input input-bordered flex my-4 w-80 h-[60px] items-center  py-2 px-3 rounded-2xl">
            <FiLock className="h-5 w-5 text-gray-400" />
            <input
              className="pl-2 max-w-xs"
              onChange={(e) => setUpass(e.target.value)}
              value={upass}
              type="password"
              name=""
              id="upass"
              placeholder="Şifre"
            />
          </label>

          <div className="flex justify-between">
            <button
              onClick={handleRegister}
              type="button"
              className="btn block w-[150px] h-[45px] bg-red-600 mt-4 py-2 rounded-2xl text-white text-sm mb-2"
            >
              Hesap Oluştur
            </button>

            <div className="flex mr-8 mt-6 ltr:ml-auto rtl:mr-auto">
              <p className="mr-2 text-gray-800">ya da</p>
              <Link to="/login" className="text-red-600 dark:text-gray-100">
                Giriş Yap.
              </Link>
            </div>
          </div>
        </form>
      </div>
      <div className="flex w-2/3 justify-start">
        <img
          src="/assets/loginpg.svg"
          alt="Logo"
          className="max-w-[600px] w-full mx-auto h-auto"
        />
      </div>
    </div>
  );
}
export default RegisterComponent;
