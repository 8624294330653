import { AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";
import { setActivePanel } from "store/popupState";
import { useDispatch } from "react-redux";
export default function ProjectTopBar() {
  const dispatch = useDispatch();

  const list = [
    { value: 1, label: "Aktif Proje" },
    { value: 2, label: "Pasif Proje" },
    { value: 3, label: "Tamamlanan Proje" },
  ];

  return (
    <div className="flex flex-row items-center justify-between ">
      <div className="relative ">
        <label className="input input-bordered flex items-center gap-2">
          <input type="text" className="grow" placeholder="Search" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="w-4 h-4 opacity-70"
          >
            <path
              fillRule="evenodd"
              d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
              clipRule="evenodd"
            />
          </svg>
        </label>
      </div>

      <div className="flex justify-end  mt-3 my-2 flex-row items-center">
        <div className="dropdown">
          <label className="form-control w-full max-w-xs">
            <select className="select select-bordered">
              {list.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </label>
        </div>

        {/* <div className="hidden md:block">
              <Select
                items={list}
                label="Durum Seçiniz"
                placeholder="Güncel Durum"
                className="w-[200px]"
                color="primary"
              >
                {(list) => (
                  <SelectItem key={list.value}>{list.label}</SelectItem>
                )}
              </Select>
            </div>
           */}

        <div className="ml-2">
          <Link to="/projects/create">
            <button
              title="Oluştur"
              className="btn dark:bg-[#7375E1] bg-[#da402f] border-0 dark:hover:bg-gray-800 hover:bg-gray-400 text-white text-sm h-12 w-full px-10"
            >
              <AiOutlinePlus className="absolute left-4 top-4 text-white text-sm" />
              <p>Oluştur</p>
            </button>
          </Link>
        </div>
        <div className="ml-2">
          <button
              onClick={() => dispatch(setActivePanel([9, 0]))}
              className="btn dark:bg-[#7375E1] bg-[#da402f] border-0 dark:hover:bg-gray-800 hover:bg-gray-400 text-white text-sm h-12 w-full px-10"
          >
            <p> Katıl</p>
          </button>
        </div>
      </div>
    </div>
  );
}
