'use client'
import React from 'react'
import ThemeComponent from './themeComponent.js'


const HeaderComponent = ({ title }) => {
  return (
    <div className="flex justify-between items-center pb-8">
      <h1 className="my-4 text-[#283252] text-[18px] dark:text-darkWhite-300 font-semibold ">{title}</h1>
      <div className="flex items-center h-7 w-20">
        <ThemeComponent />

        <img src="../../assets/bayrak.png" alt="Bayrak" className="h-[26px] w-[26px] object-center" />
      </div>
    </div>
  )
}

export default HeaderComponent
