import React from 'react'



const PAside2Component = ({ title, progress, ddata, userdata, userimg }) => {
  return (
    <div className=" border dark:border-dark-300 w-full border-gray-200  bg-white  dark:bg-dark-300  my-2  min-h-[120px] pr-3 rounded-md">
      <div className="flex flex-col">
        <h1 className=" m-4 w-[200px] text-red-600 dark:text-[#797BF2] text-[12px] font-bold">{title}</h1>
        {/* {ddata}
        <div className='rounded-full border-1 p-2 w-8 bg-stone-100'>
          {userimg}
        </div>
        <div className=' bg-cyan-100 rounded-full p-1 w-6'>
          {userdata}
          </div> */}
      </div>
    </div>
  )
}
export default PAside2Component
