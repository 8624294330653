import React, { useState, useEffect, useContext } from "react";
import { getProjectsTasks, postProjectsTasksByStatus } from "../api/projects";
import {
  FaPlus,
  FaCalendarAlt,
  FaChevronDown,
  FaList,
  FaRegListAlt,
} from "react-icons/fa";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { FiPlus } from "react-icons/fi";
import { Droppable, DragDropContext, Draggable } from "@hello-pangea/dnd";
// import SocketContext from '../../../components/socket/socket_context'
import { Block } from "notiflix/build/notiflix-block-aio";
import HeaderComponent from "mainComponents/headerComponent";
import { setTaskChanged } from "store/notifiesState";
import { useParams } from "react-router-dom";
import MenuBarComponent from "../components/MenuBarComponent";
import { setActivePanel } from "store/popupState";
import PriorityList from "constants/index";

export default function ProjectKanban(props) {
  const { id } = useParams();
  // const { socket } = useContext(SocketContext);
  const { taskChanged, proNotifyNewTaskList } = useSelector(
    (state) => state.notifies
  );
  // const { socketInfo } = useSelector((state) => state.socket);
  const [initPriority, setInıtValue] = useState("show");
  const [isListed, setIsListed] = useState(false);
  const [isBlankActive, setIsBlankActive] = useState(false);
  const [IsFullActive, setIsFullActive] = useState(false);
  const [IsEditActive, setIsEditActive] = useState(false);
  const [taskId, setTaskId] = useState(0);
  const [pid, setPID] = useState(0);
  const [tasksData, setTasksData] = useState([]);
  const [showUIElements, setShowUIElements] = useState(false);
  const [loading, setLoading] = useState(true);
  const [handleClick, setHandleClick] = useState(true);
  const dispatch = useDispatch();
  const animals = PriorityList();
  const [categories, setCategories] = useState({
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
  });

  const priority = {
    0: "bg-[#CDDC39]",
    1: "bg-[#CDDC39]",
    2: "bg-[#8BC34A]",
    3: "bg-[#039be5]",
    4: "bg-[#FF9800]",
    5: "bg-[#da402f]",
  };

  const tstatus = [
    [0, "Yeni"],
    [1, "İşlemde"],
    [2, "Hazır"],
    [3, "İncelemede"],
    [4, "Tamamlandı"],
  ];

  async function getTasks() {
    const data = { slug: id };
    const gt = await getProjectsTasks(data);
    setCategories({
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
    });
    gt.projectTasks.map((item) => {
      setCategories((prevCategories) => {
        const categoryToUpdate = item.tstatus;
        const updatedCategory = [...prevCategories[categoryToUpdate], item];
        return {
          ...prevCategories,
          [categoryToUpdate]: updatedCategory,
        };
      });
    });
    setTasksData(gt.projectTasks);
    setPID(gt.p);
    setShowUIElements(true);
  }

  async function setTask(taskid, taskstatus) {
    const data = { taskId: taskid, tstatus: taskstatus, slug: id };
    const getit = await postProjectsTasksByStatus(data);
    return getit;
  }

  useEffect(() => {
    if (showUIElements) return;
    getTasks();
  }, [!showUIElements]);

  useEffect(() => {
    dispatch(setTaskChanged(false));
    if (taskChanged == true) {
      getTasks();
    }
  }, [taskChanged]);

  async function onDragEnd(result) {
    Block.pulse(".is-kanban-wrapper");
    setLoading(false);
    const { source, destination } = result;
    if (!destination) {
      setLoading(true);
      Block.remove(".is-kanban-wrapper");
      return;
    }
    const sourceCategory = categories[source.droppableId];
    const destinationCategory = categories[destination.droppableId];
    const [movedItem] = sourceCategory.splice(source.index, 1);
    destinationCategory.splice(destination.index, 0, movedItem);
    setCategories((prev) => ({
      ...prev,
      [source.droppableId]: [...sourceCategory],
      [destination.droppableId]: [...destinationCategory],
    }));

    const gelen = await setTask(
      result.draggableId,
      result.destination.droppableId
    );
    // socket.emit("projectUpdate", {
    //   pid: pid,
    //   proc: 4,
    // });
    setLoading(true);
    Block.remove(".is-kanban-wrapper");
  }

  return (
    <div className="w-full h-screen  dark:bg-[#3B3B41]">
    <div className="container mx-auto dark:bg-[#3B3B41]">
      <div className="tabs-wrapper is-triple-slider">
        <HeaderComponent title={"Kanban"} />
        <MenuBarComponent title={"kanban"} />
        <div className="flex space-x-3 pr-4 justify-end mt-5">
          <button
            onClick={() => dispatch(setActivePanel([6, 0]))}
            className=" btn dark:bg-[#7375E1] border-0 dark:hover:bg-gray-800 hover:bg-gray-400 bg-[#da402f]  rounded-md text-white text-sm min-w-[120px] h-[45px] dark:bg-darkViolet shadow-md"
          >
            <div className="flex items-end gap-1 text-sm font-semibold">
              <FiPlus size={18} /> <span>Yeni Görev</span>
            </div>
          </button>
          {/* <button
            className="h-[45px] btn rounded-md text-black text-xs dark:bg-dark-500 bg-white"
            onClick={() => {
              setHandleClick(!handleClick);
            }}
          >
            <AiOutlineUnorderedList className="text-[18px] mx-auto dark:text-darkWhite-300 " />
          </button> */}
          <div className="min-w-[200px] h-[45px]">
            <select className="select w-full max-w-xs dark:text-gray-400 ">
              {animals.map((animal) => (
                <option key={animal.value} value={animal.value}>
                  {animal.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          id="project-board"
          data-naver-offset="214"
          data-menu-item="#layouts-navbar-menu"
          data-mobile-item="#mobile-check"
        >
          <div className="page-content-wrapper">
            <div className="page-content kanban-content is-relative">
              <div className="grid grid-cols-5 gap-3 mt-5 is-kanban-wrapper">
                {showUIElements && loading && (
                  <DragDropContext
                    onDragEnd={(result) => {
                      onDragEnd(result);
                    }}
                  >
                    {Array.from({ length: 5 }).map((item, index) => (
                      <div key={index} className="column is-one-fifth  border-0">
                        <div
                        
                          className={
                            categories[index].length === 0
                              ? "kanban-column is-empty dark:bg-[#3f3f44] border-0"
                              : "kanban-column dark:bg-[#414147] "
                          }
                        >
                          <div className="column-title">
                            <h3>
                              <span className="column-name">
                                {tstatus[index][1]}
                              </span>
                            </h3>
                          </div>

                          <Droppable droppableId={index.toString()}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {categories[index].length === 0 ? (
                                  <Draggable
                                    isDragDisabled
                                    draggableId={index.toString()}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        className="kanban-card bg-white dark:bg-[#323236] is-unselectable"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <p className="empty-text text-[#283252] dark:text-gray-400">
                                          {tstatus[index][1]} olarak tanımlanan
                                          görev bulunamadı.
                                        </p>
                                      </div>
                                    )}
                                  </Draggable>
                                ) : (
                                  categories[index].map((item, index) => {
                                    return (
                                      <Draggable
                                        key={index}
                                        draggableId={item.id.toString()}
                                        index={index}
                                      >
                                        {(provided) => (
                                          <div
                                            onClick={() => {
                                              setTaskId(item.id);
                                              setIsFullActive(true);
                                            }}
                                            className="dark:bg-[#323236] bg-white kanban-card flex"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            {" "}
                                            <div
                                              className={`rounded-l-md max-w-[4px] ${
                                                priority[item.priority]
                                              }`}
                                            >
                                              &nbsp;
                                            </div>
                                            
                                            <div className="card-body   ">
                                            <h4 className="card-title text-[#283252] dark:text-gray-400 break-all overflow-hidden overflow-wrap-anywhere hyphens-auto w-full">
                                            {item.tsubject.slice(0, 74)}
                                              </h4>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      </div>
                    ))}
                  </DragDropContext>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div></div>
  );
}
