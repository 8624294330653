import FooterComponent from '../../mainComponents/footerComponent'
import Leftbar from '../../mainComponents/leftBar'
import Popups from '../../mainComponents/popups'
import React from 'react'
import { Outlet } from 'react-router-dom'


export default function DashboardLayout({ children, ...props }) {
  return (
    <div className="bg-stone-100 min-h-screen">
      <Leftbar />
       <Popups /> 
    
      <div className='flex-col flex justify-between min-h-screen'>
        <Outlet />
   
    
      <FooterComponent />   </div>
    </div>
  )
}
