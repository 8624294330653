import React, { useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { Button, Input } from '@nextui-org/react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import { TiFolderDelete, TiFolderAdd } from 'react-icons/ti'
import { PiNotePencilBold } from 'react-icons/pi'
import { postMytaskDelete, postMytaskFinish, postMytaskSet } from '../../../services/mytask'
import { VscCircleFilled } from 'react-icons/vsc'


function TaskCardSComponent(props) {
  const [isVisible, setIsVisible] = useState(true)
  const [taskContent, setTaskContent] = useState('')
  const [handleClick, setHandleClick] = useState(false)
  const [menuSelected, setMenuSelected] = useState(0)
  const [colorSelected, setColorSelected] = useState(props.selectedTask.tpriority)
  const [colorColor, setColorColor] = useState('text-red-600')
  const [colorText, setColorText] = useState('ÖNCELİK')
  const [subject, setSubject] = useState(props.selectedTask.tsubject)
  const [content, setContent] = useState(props.selectedTask.tcontent)
  const [priority, setPriority] = useState(props.selectedTask.tpriority)
  const handlePriorityChange = (priorityValue) => {
    setPriority(priorityValue)
  }

  const handleColor = (opt, title, color) => {
    setColorSelected(opt)
    setColorText(title)
    setColorColor(`text-[${color}]`)
    handlePriorityChange(opt)
  }

  const closeModal = () => {
    setIsVisible(false)
    props.onClose()
  }

  const handleDelete = async () => {
    try {
      await postMytaskDelete(props.selectedTask.id, {})
      
      closeModal()
    } catch (error) {
     
    }
  }
  const handleUpdate = async () => {
    try {
      await postMytaskDelete(props.selectedTask.id, {})
      await postMytaskSet(props.selectedTask.id, { tsubject: subject, tcontent: content, tpriority: parseInt(priority) })
     
      closeModal()
    } catch (error) {
      
    }
  }
  const handleFinish = async () => {
    try {
      await postMytaskFinish(props.selectedTask.id, {})
      closeModal()
    } catch (error) {
      
    }
  }

  const handleSunEditorChange = (content) => {
    setTaskContent(content)
  }

  return isVisible ? (
    <div className="z-50 absolute w-full h-full" onClick={closeModal}>
      <div className="bg-[#232326] fixed opacity-80 transition-all w-full h-full  bottom-0 right-0 left-0 top-0 z-50"></div>
      <div className="py-5 px-5 bg-white w-[420px] dark:bg-dark-100 top-0 right-0 transition-all h-full fixed z-50 space-y-4 right-panel" onClick={(e) => e.stopPropagation()}>
        <div className="flex space-x-12 justify-between items-center">
          <h1 className="font-normal opacity-60 text-lg">Görev Detayları</h1>
          <div>
            <button
              className="rounded-full p-2 justify-end opacity-20 hover:bg-stone-300 "
              onClick={() => {
                setHandleClick(!handleClick)
              }}
            >
              <PiNotePencilBold size={23} />
            </button>
            <button className="rounded-full p-2 justify-end opacity-20 hover:bg-stone-300" onClick={closeModal}>
              <FaTimes size={23} />
            </button>
          </div>
        </div>
        <h3 className="text-md font-medium text-red-600 dark:text-[#797BF2]">İçerik</h3>
        {handleClick ? (
          <Input
            type="text"
            label=""
            name="tsubject"
            defaultValue={props.selectedTask.tsubject}
            onChange={(e) => {
              setSubject(e.target.value)
            }}
            className="w-full text-xl font-medium text-black dark:text-stone-300"
          />
        ) : (
          <Input
            type="text"
            label=""
            name="tsubject"
            defaultValue={props.selectedTask.tsubject}
            onChange={(e) => {
              setContent(e.target.value)
            }}
            className="w-full text-xl font-medium text-black dark:text-stone-300"
            readOnly
          />
        )}
        <div className="">
          {handleClick ? (
            <SunEditor
              defaultValue={taskContent}
              onChange={handleSunEditorChange}
              setOptions={{
                mode: 'balloon',
                height: '200',
                placeholder: 'Görev detaylarını yazınız...',
                buttonList: [['undo', 'redo', 'bold', 'underline', 'fontSize', 'fontColor', 'table', 'link', 'fullScreen', 'showBlocks']]
              }}
            />
          ) : (
            <SunEditor
              defaultValue={taskContent}
              onChange={handleSunEditorChange}
              setOptions={{
                mode: 'balloon',
                height: '200',
                placeholder: 'Görev detaylarını yazınız...',
                buttonList: [['undo', 'redo', 'bold', 'underline', 'fontSize', 'fontColor', 'table', 'link', 'fullScreen', 'showBlocks']]
              }}
              readOnly
            />
          )}
        </div>
        <div className="w-full border-t opacity-50 border-dashed border-zinc-300"></div>
        <div className="flex">
          <div id="mainarea">
            <div className="flex border-b border-dashed border-zinc-200">
              <h3 className={`w-1/5 text-center self-center text-xs font-medium ${colorColor}`}>{colorText}</h3>
              <div className="w-4/5 flex items-center content-center min-h-[80px] px-5">
                {[1, 2, 3, 4, 5].map((opt) => (
                  <VscCircleFilled
                    key={opt}
                    onClick={() => {
                      handleClick &&
                        handleColor(
                          opt,
                          opt === 1 ? 'Çok Düşük' : opt === 2 ? 'Düşük' : opt === 3 ? 'Normal' : opt === 4 ? 'Yüksek' : 'Çok Yüksek',
                          opt === 1 ? '#cddc39' : opt === 2 ? '#8bc34a' : opt === 3 ? '#039be5' : opt === 4 ? '#ff9800' : '#da402f'
                        )
                    }}
                    className="cursor-pointer rounded-full text-[60px] hover:text-[70px] transition-all duration-200"
                    color={
                      (handleClick && colorSelected === 0) || colorSelected === opt
                        ? opt === 1
                          ? '#cddc39'
                          : opt === 2
                          ? '#8bc34a'
                          : opt === 3
                          ? '#039be5'
                          : opt === 4
                          ? '#ff9800'
                          : '#da402f'
                        : '#ccc'
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full border-t opacity-50 border-dashed border-zinc-300"></div>
        <div className="flex">
          <div className="flex flex-col">
            <div className="flex">
              <TiFolderAdd size={23} className="text-[#06d6a0]" />
              <h3 className="text-sm font-medium text-[#06d6a0] ml-2">Başlangıç Tarihi</h3>
            </div>
            <div>{props.selectedTask.created_at}</div>
            <div className="flex">
              <TiFolderDelete size={23} className="text-[#da402f]" />
              <h3 className="text-sm font-medium text-[#da402f] ml-2 dark:text-[#797BF2]">Bitiş Tarihi</h3>
            </div>
            <div>{props.selectedTask.tpriority}</div>
          </div>
          <div className="flex flex-col justify-end mx-auto space-y-2 ">
            <Button className="bg-[#06d6a0] text-white text-md w-40 " variant="solid" onClick={handleFinish}>
              Bitir
            </Button>
            <Button className="bg-red-600 text-white text-md w-40" variant="solid" onClick={handleDelete}>
              Sil
            </Button>
          </div>
        </div>
        <div className="w-full border-t opacity-50 border-dashed border-zinc-300"></div>
        {handleClick && (
          <Button className="bg-[#039BE5] text-white text-md w-full" variant="solid" onClick={handleUpdate}>
            Güncelle
          </Button>
        )}
      </div>
    </div>
  ) : null
}

export default TaskCardSComponent
