import React from 'react'

export default function LandingMainTwo() {
  return (
    <div className="container mx-auto px-4 mt-16">
      <div className="flex flex-col items-center text-center">
        <h2 className="font-semibold text-4xl text-gray-800 mb-4">Projeleri Yönetmenin Kolaylığı!</h2>
        <p className="text-base text-gray-600 max-w-2xl mb-8">
          Hey Pena, projelerini geliştirirken süreç takibinde sorun yaşayan ekipler ve güncellemeleri takip etmek isteyen herkes için geliştirildi.
        </p>
        <img src="../../assets/landing/penakomp.png" alt="LandingMainTwo" className="max-w-full h-auto" />
      </div>
    </div>
  )
}