import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setActivePanel } from 'store/popupState'
import { postMytaskSet,postMytask } from 'services/mytask'
import { FaTimes } from 'react-icons/fa'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import { VscCircleFilled } from 'react-icons/vsc'

function TaskComponent(props) {
  
  const [menuSelected, setMenuSelected] = useState(0)
  const [colorSelected, setColorSelected] = useState(0)
  const [colorColor, setColorColor] = useState('text-red-600')
  const [colorText, setColorText] = useState('ÖNCELİK')
  const dispatch = useDispatch()
  const [subject, setSubject] = useState('')
  const [content, setContent] = useState('')
  const [priority, setPriority] = useState(0)
  let temp = ''

  const getTaskDetails = async () => {
    const data = {
      "taskid" : props.type
    }
    const get = await postMytask(data);
    setSubject(get.userTasks.tsubject);
    //TODO: diğerleri set edilecek -oğuz
  }
  
  useEffect(() => {
    if(props.type != 0){
      getTaskDetails();
    }
  }, [])

  const handlePriorityChange = (priorityValue) => {
    setPriority(priorityValue)
  }

  const handleColor = (opt, title, color) => {
    setColorSelected(opt)
    setColorText(title)
    setColorColor(`text-[${color}]`)
    handlePriorityChange(opt)
  }

  const handleInputChange = (e) => {
    setContent(e.target.value)
  }

  const handleInput = (e) => {
    setSubject(e.target.value)
    temp = e.target.value
  }

  const handleSubmit = async () => {
    try {
      const response = await postMytaskSet('user_id', {
        tsubject: subject === '' ? 'bozuk burası kodun' : subject,
        tcontent: content,
        tpriority: priority
      })
      dispatch(setActivePanel([0, 0]))
    } catch (error) {
      
    }
  }

  const handleSunEditorChange = (content) => {
    handleInputChange({ target: { name: 'tcontent', value: content } })
  }

  return (
    <div className="z-50 absolute w-full h-full">
      <div onClick={() => dispatch(setActivePanel([0, 0]))} className="bg-[#232326] opacity-80 transition-all w-full h-full relative bottom-0 right-0 left-0 top-0 z-50"></div>
      <div className="p-4 bg-white w-[420px] dark:bg-dark-100 top-0 right-0 transition-all h-full fixed z-50 space-y-4">
        <div className="flex justify-between">
          <h1 className="font-normal opacity-50 text-lg">Görev Detayları</h1>
          <button onClick={() => dispatch(setActivePanel([0, 0]))} className="rounded-full p-2 justify-end opacity-20 hover:bg-stone-300">
            <FaTimes size={23} />
          </button>
        </div>
        <h3 className="text-xs font-medium text-red-600 dark:text-[#797BF2]">İÇERİK</h3>
        <div className="w-full flex justify-start text-black dark:text-stone-300 text-md">
        <input type="text" placeholder="Görev Başlığı.." label="Görev Başlığı.." name="tsubject" value={subject} onChange={handleInput}  className="input input-bordered w-full max-w-xs" />
        </div>
        <div className="w-full h-[200px] flex ">
          <SunEditor
            defaultValue={content}
            onChange={handleSunEditorChange}
            setOptions={{
              mode: 'balloon',
              height: '200',
              placeholder: 'Görev detaylarını yazınız...',
              buttonList: [['undo', 'redo', 'bold', 'underline', 'fontSize', 'fontColor', 'table', 'link', 'fullScreen', 'showBlocks']]
            }}
          />
        </div>
        <div className="w-full border-t opacity-50 border-dashed border-zinc-300"></div>
        <div className="flex">
          <div id="mainarea">
            <div className="flex border-b border-dashed border-zinc-200">
              <h3 className={`w-1/5 text-center self-center text-xs font-medium ${colorColor}`}>{colorText}</h3>
              <div className="w-4/5 flex items-center content-center min-h-[80px] px-5">
                {[1, 2, 3, 4, 5].map((opt) => (
                  <VscCircleFilled
                    key={opt}
                    onClick={() =>
                      handleColor(
                        opt,
                        opt === 1 ? 'Çok Düşük' : opt === 2 ? 'Düşük' : opt === 3 ? 'Normal' : opt === 4 ? 'Yüksek' : 'Çok Yüksek',
                        opt === 1 ? '#cddc39' : opt === 2 ? '#8bc34a' : opt === 3 ? '#039be5' : opt === 4 ? '#ff9800' : '#da402f'
                      )
                    }
                    className="cursor-pointer rounded-full text-[60px] hover:text-[70px] transition-all duration-200"
                    color={
                      colorSelected === 0 || colorSelected === opt
                        ? opt === 1
                          ? '#cddc39'
                          : opt === 2
                          ? '#8bc34a'
                          : opt === 3
                          ? '#039be5'
                          : opt === 4
                          ? '#ff9800'
                          : '#da402f'
                        : '#ccc'
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full border-t opacity-50 border-dashed border-zinc-300"></div>
        <button className="btn bg-red-600 text-white text-md w-full dark:bg-[#797BF2]" variant="solid" onClick={handleSubmit}>
          Gönder
        </button>
        <div className="w-full border-t opacity-50 border-dashed border-zinc-300"></div>
      </div>
    </div>
  )
}

export default TaskComponent
