import React, { useState, useEffect } from "react";
import { FiMail, FiLock } from "react-icons/fi";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { useNavigate } from "react-router-dom";
import { Notify } from "notiflix";
import { postGoogleLogin } from "../api/google";
import { setToken, getToken, deleteToken } from "../../../libs/token.js";
import { login } from "../api/auth";
import { Link } from "react-router-dom";

//ToDo: buradaki inputlar daisy input ile değiştirilcek.
function LoginComponent() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    const existingToken = getToken("penatoken");
    try {
      if (existingToken) {
        const token = await deleteToken("penatoken");
      }

      const response = await login({
        email: email,
        password: password,
      });
      if (response) {
        setToken("penatoken", response);
        setToken("user", JSON.stringify(response.user));
        Notify.success("Başarıyla giriş yapıldı!");
        navigate("/dashboard");
      } else {
        Notify.failure("Bilgilerinizi kontrol ediniz.", {
          position: "right-bottom",
        });
      }
    } catch (error) {
      Notify.failure("Lütfen bilgilerinizi kontrol ediniz.", {
        position: "right-bottom",
      });
    }
  };

  const responseGoogle = async (response) => {
    const googleproc = await postGoogleLogin({ token: response.credential });

    if (googleproc.statusCode === 401) {
      Notify.failure("Google ile giriş yapma başarısız oldu!");
    } else {
      setToken("penatoken", googleproc);
      setToken("user", JSON.stringify(googleproc.user));
      Notify.success("Başarıyla giriş yapıldı!");
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    Loading.pulse();
    /* global google */
    if (typeof window !== undefined) {
      const google = window.google;
      if (google !== undefined) {
        google.accounts.id.initialize({
          client_id:
            "308606892028-937t32a35c76uku2tqmm7pgfke23ai7r.apps.googleusercontent.com",
          callback: responseGoogle,
        });
        google.accounts.id.renderButton(document.getElementById("buttonDiv"), {
          theme: "outline",
          size: "large",
          width: "100%",
        });
      }
      setLoading(true);
    }
    Loading.remove();
  }, [loading]);

  return (
    <div className="h-screen flex lg:flex-row flex-col  bg-[#fdfdfd]">
      <div className="flex lg:w-2/3 w-full justify-center">
        <img
          src="/assets/loginpg.svg"
          alt="Logo"
          className="max-w-[600px] w-full h-auto mr-2 mt-3"
        />
      </div>

      <div className="flex lg:w-1/3 w-full justify-center items-center bg-white  border-l border-gray-200">
        <div className="bg-white ">
          <h1 className="text-red-600 text-3xl ">Giriş Yap</h1>
          <p className="text-sm font-normal text-gray-600 mb-10 my-4">
            Giriş yapmak için formu kullanabilirsin.
          </p>
          <label className=" input input-bordered flex w-80 h-[60px] items-center  py-2 px-3 rounded-2xl mb-5">
            <FiMail className="h-5 w-5 text-gray-400" />
            <input
              type="text"
              onKeyDown={handleKeyDown}
              onChange={(e) => setEmail(e.target.value)}
              className="   max-w-xs pl-2 "
              name=""
              id=""
              placeholder="E-Posta"
            />
          </label>
          <label className="input input-bordered flex my-4 w-80 h-[60px]  items-center  py-2 px-3 rounded-2xl">
            <FiLock className="h-5 w-5 text-gray-400" />
            <input
              onKeyDown={handleKeyDown}
              onChange={(e) => setPassword(e.target.value)}
              className=" pl-2 max-w-xs "
              type="password"
              name=""
              id=""
              placeholder="Şifre"
            />
          </label>
          <span className="flex justify-end text-sm ml-2 opacity-50 hover:text-red-600 cursor-pointer">
            Şifreni mi unuttun?
          </span>
          <div className="flex justify-between">
            <button
              onClick={() => handleSubmit()}
              type="submit"
              className="btn  block w-[130px] h-[45px] bg-red-600 hover:bg-gray-400 dark:hover:bg-gray-800 border-0 mt-4 py-2 rounded-2xl text-white font-semibold mb-2"
            >
              Giriş Yap
            </button>

             <div className="flex mt-6 ltr:ml-auto rtl:mr-auto">
              <p className="mr-4 text-gray-800">ya da</p>
              <Link to="/register" className="text-red-600 dark:text-gray-100">
                Hesap oluştur.
              </Link>
            </div> 
          </div>
          <div id="buttonDiv"></div>
        </div>
      </div>
    </div>
  );
}
export default LoginComponent;
