import React from "react";


const NumberComponent = ({ number, number1, number2, number3 }) => {
  return (
    <div className="flex  lg:justify-end justify-center md:h-[90px] dark:text-gray-400">
      <div className="border rounded-md dark:border-dark-300 bg-white  dark:bg-dark-300 ">
        <div className=" dark:border-dark-100 w-full ">
          <div className="grid grid-cols-4  text-center font-sans  dark:text-dark White-300 py-5">
            <div className=" font-bold text-2xl mx-auto">
              <div className="md:text-3xl text-xl rounded-full ">
                <div className=" flex mx-auto w-5 h-8">
                  <div className="z-0 justify-center ">{number}</div>
                  <div className="z-30 w-2 h-2 justify-end rounded-full items-end bg-[#337357]">
                    &nbsp;&nbsp;
                  </div>
                </div>
                <p className="md:text-sm text-base font-semibold opacity-40 mx-4">
                  Aktif
                </p>
              </div>
            </div>

            <div className="  font-bold text-2xl mx-auto">
              <div className="md:text-3xl text-xl rounded-full ">
                <div className=" flex mx-auto w-5 h-8">
                  <div className="z-0 justify-center">{number1}</div>
                  <div className="z-30 w-2 h-2 justify-end rounded-full items-end bg-[#E20E1B]">
                    &nbsp;&nbsp;
                  </div>
                </div>
                <p className="md:text-sm text-base font-semibold  opacity-40 mx-4">
                  Pasif
                </p>
              </div>
            </div>

            <div className=" font-bold text-2xl mx-auto">
              <div className="md:text-3xl text-xl rounded-full" content="">
                <div className=" flex mx-auto w-5 h-8">
                  <div className="z-0 justify-center">{number2}</div>
                  <div className="z-30 w-2 h-2 justify-end rounded-full items-end bg-[#FFD23F]">
                    &nbsp;&nbsp;
                  </div>
                </div>
                <p className="md:text-sm text-base font-semibold  opacity-40 mx-4">
                  Tamamlanan
                </p>
              </div>
            </div>

            <div className=" font-bold text-2xl mx-auto">
              <div className="md:text-3xl text-xl rounded-full" content="">
                <div className=" flex mx-auto w-5 h-8">
                  <div className="z-0 justify-center">{number3}</div>
                  <div className="z-30 w-2 h-2 justify-end rounded-full items-end bg-[#5E1675]">
                    &nbsp;&nbsp;
                  </div>
                </div>
                <p className="md:text-sm text-base font-semibold  opacity-40 mx-4">
                  Toplam
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberComponent;
