import React, { useState } from "react";
import { FiUser, FiGrid, FiMenu } from "react-icons/fi";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsActivity } from "react-icons/bs";
import { CgDatabase } from "react-icons/cg";
import { IoIosContacts } from "react-icons/io";
import { PiBagSimpleLight } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import { IoExitOutline } from "react-icons/io5";
import { FaBell } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// import { LogoutProcess } from "@/../pages/api/auth"; yok -emreü
import { useLocation } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import { deleteToken } from "../libs/token";
//YENİ APİYE GEÇİLMELİ

export default function Leftbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();


  async function handleLogOut() {
    const token = await deleteToken("penatoken");
    navigate("/login");
  }

  const path = location.pathname.substring(1);
  const iconClasses =
    "text-xl text-default-500 pointer-events-none flex-shrink-0";

  return (
    <div className={`lg:block z-50 relative`}>
    
      <div
        className={`vertical-menu left-0 bottom-0 h-screen print:hidden  dark:border-neutral-700 float-left fixed bg-white dark:bg-dark-500 ${
          isMenuOpen ? "w-0" : "border-r bg-white dark:bg-dark-500 w-[80px]"
        }`}
      >
        <div className="h-screen">
          <div className="w-screen bg-white dark:bg-[#232326] sticky justify-between z-50 h-20 flex flex-row items-center lg:hidden px-10">
            <FiMenu
              className=" text-gray-400 cursor-pointer block lg:hidden "
              size={24}
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
            />
            <img
              src="/assets/penalogo.svg"
              alt="Logo"
              className="w-1/12 h-auto"
            />
            <FaBell className="dark:fill-[#929293] fill-black hover:fill-[#039BE5]  hover:rounded-full hover:shadow-xl" />
          </div>
          <div>
            <div
              id="leftbar-menu"
              className={`lg:block ${isMenuOpen ? "hidden" : "block"}`}
            >
              <ul className="metismenu" id="side-menu">
                <li>
                  <ul>
                    <li>
                      <Link
                        to="/dashboard"
                        className={`pl-2 pr-2 py-1 hidden lg:block text-xs font-bold text-gray-400 transition-all duration-150 ease-linear hover:text-red-600  dark:text-gray-300 dark:active:text-white dark:hover:text-white`}
                      >
                        <img
                          src="/assets/penalogo.svg"
                          alt="Logo"
                          className="w-[35px] h-auto mr-2 ml-4 mt-3 "
                        />
                      </Link>
                    </li>
                    <li
                      className={`border-l-4  ${
                        path === "dashboard"
                          ? "border-red-500 dark:border-[#797BF2]"
                          : "border-white dark:border-[#232326]"
                      }`}
                    >
                      <Link
                        to="/dashboard"
                        className="text-center w-[80px] mb-5 block text-xs font-bold text-gray-400 transition-all duration-150 ease-linear hover:text-red-600 dark:text-gray-300 dark:active:text-white dark:hover:text-white"
                      >
                        <BsActivity
                          className={`mt-6 text-[20px] mx-auto  ${
                            path === "dashboard" &&
                            "text-red-500 dark:text-[#797BF2]"
                          }`}
                        />
                        <h1
                          className={`opacity-50 text-[#a1a1aa] text-[8px] font-roboto hover:text-[#E18680] dark:hover:text-[#797BF2] ${
                            path === "dashboard" &&
                            "text-red-500 dark:text-[#797BF2]"
                          }`}
                        >
                          DASHBOARD
                        </h1>
                      </Link>
                    </li>
                    <li
                      className={`border-l-4  ${
                        path === "projects"
                          ? "border-red-500 dark:border-[#797BF2]"
                          : "border-white dark:border-[#232326]"
                      }`}
                    >
                      <Link
                        to="/projects"
                        className="text-center w-[80px] mb-5 py-1 block text-xs font-bold text-gray-400 transition-all duration-150 ease-linear hover:text-red-600 dark:text-gray-300 dark:active:text-white dark:hover:text-white"
                      >
                        <CgDatabase
                          className={`text-[20px] mx-auto  ${
                            path === "projects" &&
                            "text-red-500 dark:text-[#797BF2]"
                          }`}
                        />
                        <h1
                          className={`opacity-50 text-[#a1a1aa] text-[8px] font-roboto hover:text-[#E18680] dark:hover:text-[#797BF2] ${
                            path === "projects" &&
                            "text-red-500 dark:text-[#797BF2]"
                          }`}
                        >
                          PROJELER
                        </h1>
                      </Link>
                    </li>

                    <li>
                      <ul>
                        {/* <li
                          className={`border-l-4  ${
                            path === "tasks"
                              ? "border-red-500 dark:border-[#797BF2]"
                              : "border-white dark:border-[#232326]"
                          }`}
                        >
                          <Link
                            to="/tasks"
                            className="text-center w-[80px] mb-5 py-1 block text-xs font-bold text-gray-400 transition-all duration-150 ease-linear hover:text-red-600 dark:text-gray-300 dark:active:text-white dark:hover:text-white"
                          >
                            <FiGrid
                              className={`text-[20px] mx-auto  ${
                                path === "tasks" &&
                                "text-red-500 dark:text-[#797BF2]"
                              }`}
                            />
                            <h1
                              className={`opacity-50 text-[#a1a1aa] text-[8px] font-roboto hover:text-[#E18680] dark:hover:text-[#797BF2]  ${
                                path === "tasks" &&
                                "text-red-500 dark:text-[#797BF2]"
                              }`}
                            >
                              GÖREVLER
                            </h1>
                          </Link>
                        </li> */}
                        {/* <li
                          className={`border-l-4  ${
                            path === "calendar"
                              ? "border-red-500 dark:border-[#797BF2]"
                              : "border-white dark:border-[#232326]"
                          }`}
                        >
                          <Link
                            to="/calendar"
                            className="text-center w-[80px]  mb-5 py-1 block text-xs font-bold text-gray-400 transition-all duration-150 ease-linear hover:text-red-600 dark:text-gray-300 dark:active:text-white dark:hover:text-white"
                          >
                            <AiOutlineCalendar
                              className={`text-[20px] mx-auto  ${
                                path === "calendar" &&
                                "text-red-500 dark:text-[#797BF2]"
                              }`}
                            />
                            <h1
                              className={`opacity-50 text-[#a1a1aa] text-[8px] font-roboto hover:text-[#E18680] dark:hover:text-[#797BF2] ${
                                path === "calendar" &&
                                "text-red-500 dark:text-[#797BF2]"
                              }`}
                            >
                              TAKVİM
                            </h1>
                          </Link>
                        </li> */}
                      </ul>
                      <div className="flex gap-1 flex-col absolute bottom-0 right-1 mb-4">
                        <div
                          tabIndex={0}
                          role="button"
                          className="btn m-1 bg-transparent border border-none hover:bg-gray-50"
                        >
                          <IoSearch className="text-[20px] ml-1 cursor-pointer text-[#a1a1aa] mx-2" />
                        </div>

                        <div className="dropdown dropdown-right dropdown-end ">
                          <div
                            tabIndex={0}
                            role="button"
                            className="btn m-1 bg-transparent border border-none hover:bg-gray-50"
                          >
                            <FiUser className="text-[20px] ml-1 cursor-pointer text-[#a1a1aa] mx-2" />
                          </div>
                          <ul
                            tabIndex={0}
                            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-60"
                          >
                            <div className="flex items-center mt-2">
                              <FiUser className="text-[20px] ml-1 cursor-pointer text-[#a1a1aa] mx-2" />
                              <div>
                                <p className="font-bold">
                                  Profil <br />
                                </p>
                                <p>Hesap ayarları</p>
                              </div>
                            </div>
                            <div className="flex items-center mt-2">
                              <PiBagSimpleLight className="text-xl text-default-500 pointer-events-none flex-shrink-0 mx-2 ml-1" />
                              <div>
                                <p className="font-bold">
                                  Organizasyonlar <br />
                                </p>
                                <p>Organizasyon seçin</p>
                              </div>
                            </div>
                            <div className="flex items-center mt-2">
                              <IoIosContacts className="text-xl text-default-500 pointer-events-none flex-shrink-0 mx-2 ml-1" />
                              <div>
                                <p className="font-bold">
                                  Bağlantılar <br />
                                </p>
                                <p>Bağlantılarınızı düzenleyin</p>
                              </div>
                            </div>
                            <div className="flex items-center mt-2">
                              <IoSettingsOutline className="text-xl text-default-500 pointer-events-none flex-shrink-0 mx-2 ml-1" />
                              <div>
                                <p className="font-bold">
                                  Ayarlar <br />
                                </p>
                                <p>Hesap ayarları</p>
                              </div>
                            </div>
                            <div className="bg-[#da402f] rounded-xl mt-4 w-full h-10 text-center flex items-center justify-center text-white gap-2 ">
                              <button className="flex items-center gap-2" onClick={handleLogOut}><IoExitOutline /> <p> Çıkış Yap</p></button> 
                            </div>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
