import React from 'react'

export default function loading() {
  return (
    <div className="w-full h-screen flex justify-center items-center dark:bg-dark-100">
      <img src="../../assets/penalogo.svg" alt="Heypena Logo" width={200} height={200} className="animate-pulse" />
    </div>
  )
}

