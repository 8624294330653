'use client'
import React from 'react'

function FooterComponent() {

  function getYear() {
    return new Date().getFullYear();
  }

  return (
    <div className="bottom-0 bg-stone-100 w-full h-full border-t border-zinc-300 py-10 mt-4 dark:mt-0 dark:bg-[#3B3B41] ">
      <p className="text-zinc-400 text-center text-sm">@ {getYear()} | Hey Pena, All rights reserved</p>
      
    </div>
    
  )
}

export default FooterComponent
