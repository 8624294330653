import axiosClient from '../../../../libs/axios'
export const getDashboard = async () => {
  const response = await axiosClient.get('/dashboard')
  return response.data 
}
export const getDashboardUserLastProjects = async () => {
  const response = await axiosClient.get('/dashboard/userlastprojects')
  return response.data
}
export const getDashboardUserLastCalendars = async () => {
  const response = await axiosClient.get('/dashboard/userlastcalendars')
  return response.data
}
