import React from 'react'

export default function LandingMainA() {
  return (
    <div className="container mx-auto px-4 mt-8">
      <div className="flex flex-col lg:flex-row justify-between items-center">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-6xl font-bold text-[#3f3b3b] font-roboto leading-tight">
            Her projenin bir
            <span className="text-[#e8420e]"> Pena</span>'ya <br /> ihtiyacı var.
          </h1>
          <p className="text-base text-gray-600 font-roboto font-normal max-w-md mt-4 mx-auto lg:mx-0">
            Projeler oluşturun, görevler ekleyin, takviminizi planlayın ve takımınızla iletişim halinde olun. Dahası için hemen başlayın!
          </p>
        </div>

        <div className="flex flex-col md:flex-row gap-6 mt-8 lg:mt-0">
          <div className="max-w-[250px] rounded-2xl overflow-hidden shadow-lg">
            <img className="w-full h-auto" src="/assets/landing/homebg.png" alt="Pena Home BG 1" />
            <div className="px-6 py-4 bg-white">
              <p className="text-gray-700 text-base mt-2">Projeni oluştur, ekibini kur ve süreci başlat!</p>
            </div>
          </div>

          <div className="max-w-[250px] rounded-2xl overflow-hidden">
            <img className="w-full h-auto" src="/assets/landing/penapho.png" alt="Pena Home BG 2" />
          </div>
        </div>
      </div>
    </div>
  )
}