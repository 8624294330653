import { useState, useEffect } from "react";
import HeaderComponent from "mainComponents/headerComponent";
import ProjectList from "./components/List";
import NumberComponent from "./components/NumberComponent";
import ProjectTopBar from "./components/TopBar";
import { getProjects } from "./api/projects";

export default function Projects() {
  const [ProjectsData, setProjectsData] = useState({});
  const [pageReady, setPageReady] = useState(false);


  async function fetchData() {
    const get = await getProjects();
    setProjectsData(get);
    setPageReady(true);
  }
  
  useEffect(() => {
    fetchData();
  }, []);

  // ProjectsData ve ProjectsData.projects null veya undefined değilse filtreleme işlemini yap
  const ongoingProjects = ProjectsData && ProjectsData.projects ? ProjectsData.projects.filter(project => project.projectstatus === 1) : [];
  // const finishedProjects = ProjectsData && ProjectsData.projects ? ProjectsData.projects.filter(project => project.projectstatus === 2) : [];
  // const deletedProjects = ProjectsData && ProjectsData.projects ? ProjectsData.projects.filter(project => project.projectstatus === 3) : [];


  return pageReady && (
    <div className=" w-full dark:bg-dark-100 min-h-[100vh] bg-stone-100 transition duration-300 flex justify-center  lg:mt-0 mt-20">
      <div className="container mx-auto ">
        <HeaderComponent title={"Projeler"} />
        <NumberComponent
          number={ProjectsData.counts.activeProjects}
          number1={ProjectsData.counts.passedProjects}
          number2={ProjectsData.counts.finishedProjects}
          number3={ProjectsData.counts.projectsCount}
        />
        <ProjectTopBar />
        <ProjectList data={ongoingProjects} />
      </div>
    </div>
  );
}
