import React, { useEffect, useState } from 'react';
import ActivComponent from "./components/activComponent.js"
import AsideComponent from './components/asideComponent.js'
import WComeComponent from './components/wComeComponent.js'
import HeaderComponent from 'mainComponents/headerComponent'
import { getDashboard } from './api/dashboard.js'

export default function Dashboard() {
  const [dashData, setDashData] = useState({});
  const [userinfo, setUserinfo] = useState();
  const [projectsinfo, setProjectsinfo] = useState([]);
  const [userstask, setUserstask] = useState([]);
  const [usercalendar, setUsercalendar] = useState([]);

  async function fetchData() {
    const data = await getDashboard();
    setDashData(data);
    setUserinfo(data.userInfo);
    setProjectsinfo(data.lastProjects);
    setUserstask(data.userTasks);
    setUsercalendar(data.lastCalendar);
}
  useEffect(() => {
      fetchData();
  }, []);

    // const dashData = (await getDashboard()) || []
    // const userinfo = dashData.userInfo
    // const projectsinfo = dashData.lastProjects
    // const userstask = dashData.userTasks
    // const usercalendar = dashData.lastCalendar

    return (
      <div className="w-full dark:bg-dark-100 bg-stone-100 transition duration-300 flex flex-col  lg:mt-0 mt-20">
        <div className="container mx-auto ">
          <HeaderComponent title={'Dashboard'} />
          <WComeComponent userstaskCount={userstask.userTaskCount} userinfo={userinfo} isCheck={true} />

          <div className="flex flex-col lg:flex-row md:mt-8">
            <div className="lg:basis-3/4 w-full">
              <ActivComponent
                title={'Aktif Projeler'}
                darkImage={"/assets/dashboard/aktifdark.svg"}
                lightImage={"/assets/dashboard/aktifp.svg"}
                desc={'Proje listeniz boş gözüküyor. Buraya tıklayarak proje oluşturabilirsiniz.'}
                data={projectsinfo}
                isProject={true}
              />
            </div>
            <div className="lg:basis-1/4 lg:mt-0 my-4 w-full">
              <AsideComponent
                titlepos={'top'}
                title={'Görevlerim'}
                darkImage={"/assets/dashboard/gorevlerimdark.svg"}
                lightImage={"/assets/dashboard/gorevlerim.svg"}
                desc1={'Görev listeniz boş gözüküyor. Buraya tıklayarak bir görev ekleyin.'}
                tasks={userstask.info}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row ">
            <div className="lg:basis-3/4 w-full">
              <ActivComponent
                title={'Yaklaşan Etkinlikler'}
                darkImage={"/assets/dashboard/yaklasanedark.svg"}
                lightImage={"/assets/dashboard/yaklasanE.svg"}
                desc={'Etkinlik listeniz boş görünüyor. Buraya tıklayarak bir etkinlik oluşturabilirsiniz.'}
                data={usercalendar}
                isProject={false}
              />
            </div>
            <div className="lg:basis-1/4 lg:mt-0 my-4 w-full">
              <AsideComponent
                titlepos={'bottom'}
                title={'Hesabı yükselt'}
                darkImage={"/assets/dashboard/hesabiydark.svg"}
                lightImage={"/assets/dashboard/hesapY.svg"}
                desc1={"Premium'a geçerek daha fazla özellik ve iş aracının kilidini açın."}
              />
            </div>
          </div>
        </div>
      </div>
    )
}
