import React from 'react'



const PAsideComponent = ({total, current, finished}) => {
  return (
    <div className="border dark:border-dark-300 w-full border-gray-200  bg-white  dark:bg-dark-300 min-h-[270px] mt-4 rounded-md">
      <div className="flex flex-col justify-center ">
        <h2 className="text-red-600  dark:text-[#797BF2] text-[12px] font-bold mt-8 ml-8">İSTATİSTİK PANELİ</h2>
        <div className="p-5">
          <div className="flex p-2">
            <button className=" bg-stone-100  w-[32px] rounded-lg dark:text-darkWhite-300">{total}</button>
            <div className="flex flex-col pl-3">
              <h2 className="text-black dark:text-darkWhite-300 text-md font-bold ">Toplam Görev Sayısı</h2>
              <p className="opacity-50 text-xs">Projeye ait toplam görev sayısı</p>
            </div>
          </div>
          <div className="flex p-2">
            <button className=" bg-stone-100 w-[32px] rounded-lg dark:text-darkWhite-300">{current}</button>
            <div className="flex flex-col pl-3">
              <h2 className="text-black dark:text-darkWhite-300 text-md font-bold ">Devam Eden Görevler</h2>
              <p className="opacity-50 text-xs">Projeye ait devam eden görev sayısı</p>
            </div>
          </div>
          <div className="flex p-2">
            <button className="bg-stone-100 w-[32px] rounded-lg dark:text-darkWhite-300">{finished}</button>
            <div className="flex flex-col pl-3">
              <h2 className="text-black dark:text-darkWhite-300 text-md font-bold ">Tamamlanan Görevler</h2>
              <p className="opacity-50 text-xs">Projeye ait tamamlanan görev sayısı</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PAsideComponent