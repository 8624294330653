import React, { useState, useEffect } from 'react';

const CircularProgressComponent = ({ items }) => {
  const { width, height, priority } = items;
  const colors = ['#CDDC39', '#8BC34A', '#039be5', '#FF9800', '#da402f'];

  const [progress, setProgress] = useState(0);

  const progressStyle = {
    width: width,
    height: height,
  };

  const indicatorStyle = {
    stroke: colors[priority - 1],
    transition: 'stroke-dashoffset 1s ease-in-out',
    strokeDasharray: 125.66,
    strokeDashoffset: 125.66 - (progress / 100) * 125.66,
    strokeWidth: 8,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
    }, 50); 

    setTimeout(() => {
      clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  }, []); 

  return (
    <svg className={`text-${colors[priority - 1]}`} style={progressStyle} viewBox="0 0 50 50">
      <circle className="stroke-current" style={indicatorStyle} cx="25" cy="25" r="20" fill="none" />
    </svg>
  );
};

export default CircularProgressComponent;
