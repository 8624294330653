import React from "react";
import { MdOutlinePlayCircleFilled } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setActivePanel } from "../../../store/popupState";
import { useState } from "react";
import { postProjectsUserJointo } from "../../../services/projects";
import { Notify } from 'notiflix'

function SearchComponent(props) {
  const { register } = props;
  const dispatch = useDispatch();
  const [slug, setSlug] = useState("");
  async function handleSubmit() {
    try {
    
    const data = {
      slug,
    };
    const newservice = await postProjectsUserJointo(data);
    dispatch(setActivePanel([0, 0]))
    Notify.success('Başarıyla istek gönderildi!')
  }  catch (error) {
    Notify.failure('Proje bulunamadı.', { position: 'right-bottom' })
  }
}
  return (
    <div className="z-50 fixed inset-0 flex justify-end">
      <div
        onClick={() => dispatch(setActivePanel([0, 0]))}
        className="bg-[#232326] opacity-80 transition-all fixed inset-0 z-40"
      ></div>
      <div className="bg-white w-[368px] p-6 rounded-l-lg shadow-lg relative z-50 h-full flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h1 className="font-normal text-gray-700 opacity-60">
            Katılmak istediğiniz proje:{" "}
          </h1>
          <button
            onClick={() => dispatch(setActivePanel([0, 0]))}
            className="rounded-full p-2 hover:bg-stone-300 transition"
          >
            <MdOutlinePlayCircleFilled size={24} />
          </button>
        </div>
        <input
          onChange={(e) => setSlug(e.target.value)}
          type="text"
          placeholder="Proje sahibinden aldığınız kodu yapıştırın"
          className="input w-full border border-gray-300 p-2 rounded-lg mb-4"
        />
        <button className="btn bg-[#da402f] text-white w-full py-2 rounded-lg hover:bg-[#D2D2D2] transition" onClick={() => handleSubmit()}>
          İstek Gönder
        </button>
      </div>
    </div>
  );
}
export default SearchComponent;
