import React from 'react';

const AcceptedComponent = ({ darkImage, lightImage }) => {
  return (
    <div className="flex justify-center text-center p-4">
      <div className="text-lg font-semibold text-gray-39 dark:text-darkWhite-300">
        Tebrikler! Hazırsınız.
        <div className="text-base font-semibold opacity-50 text-gray-39 dark:text-darkWhite-300">
          Harika, proje oluşturmayı tamamladınız.
        </div>
        <div className="w-full h-auto p-8">
          <img
            src={darkImage || "/assets/finishdark.svg"}
            className="hidden dark:block mx-auto"
            width={200}
            height={100}
            alt=""
          />
          <img
            src={lightImage || "/assets/finish.svg"}
            className="dark:hidden mx-auto"
            width={200}
            height={100}
            alt=""
          />
        </div>
        <div className="text-lg font-semibold text-gray-39 dark:text-darkWhite-300">
          Sonraki adımlar için hazır olun.
        </div>
        <div className="font-roboto text-base text-gray-39 dark:text-darkWhite-300 font-medium opacity-50 max-w-[620px]">
          Yeni ekip üyeleri ekleyerek çalışmaya ve görevler oluşturmaya
          <br /> başlayabilirsiniz.
        </div>
      </div>
    </div>
  );
};

export default AcceptedComponent;
