import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { Button } from '@nextui-org/react'
import { BsPatchExclamation, BsFileCheck } from 'react-icons/bs'
import { FiUsers } from 'react-icons/fi'
import { CiClock2 } from 'react-icons/ci'
import { AiFillCheckCircle } from 'react-icons/ai'
import { RiDeleteBinFill } from 'react-icons/ri'
import { BsPencilSquare } from 'react-icons/bs'
import { Textarea } from '@nextui-org/react'

function TaskDatailComponent(props) {
  

  return (
    <div className="z-50 absolute w-full h-full">
      <div className="bg-[#232326] opacity-80 transition-all w-full h-full relative bottom-0 right-0 left-0 top-0 z-50"></div>
      <div className="p-4 bg-white w-[420px] dark:bg-dark-100 top-0 right-0 transition-all h-full fixed z-50 space-y-4">
        <div className="flex justify-between">
          <h1 className="font-normal opacity-50 text-lg">Görev Detayları</h1>
          <div className="flex justify-between">
            <button className="rounded-full p-2 opacity-50 hover:bg-stone-300">
              <AiFillCheckCircle size={23} />
            </button>
            <button className="rounded-full p-2  opacity-50 hover:bg-stone-300">
              <RiDeleteBinFill size={23} />
            </button>
            <button className="rounded-full p-2 opacity-50 hover:bg-stone-300">
              <BsPencilSquare size={23} />
            </button>
            <button className="rounded-full p-2 opacity-50 hover:bg-stone-300">
              <FaTimes size={23} />
            </button>
          </div>
        </div>
        <h3 className="text-xs font-medium text-red-600">İÇERİK</h3>

        <div className="">
          <Textarea label="sdfghjklş" labelPlacement="outside" placeholder="" className="w-[600px]  text-black fond-semibold text-sm" />
        </div>
        <div className="w-full border-t opacity-50 border-dashed border-zinc-300"></div>
        <div className="flex w-4/5  items-center content-center min-h-[80px] px-5">
          <div>
            <BsPatchExclamation className="" size={23} color="#dc2626" />
            <h3 className="text-[12px] mt-2 font-medium text-black">Öncelik</h3>
          </div>
          <div>
            <FiUsers className="" size={20} color="#dc2626" />
            <h3 className="text-[12px] mt-3 font-medium text-black">Tüm Kullanıcılar</h3>
          </div>
          <div>
            <CiClock2 className="" size={25} fill="#dc2626" color="#dc2626" />
            <h3 className="text-[12px] mt-2 font-medium text-black">Teslim</h3>
          </div>
          <div>
            <BsFileCheck className="" size={23} color="#dc2626" />
            <h3 className="text-[12px] mt-3 font-medium text-black">Durum</h3>
          </div>
        </div>

        <div className="w-full border-t opacity-50 border-dashed border-zinc-300"></div>
        <Button className="bg-red-600 text-white text-md w-full" variant="solid">
          Gönder
        </Button>
        <div className="w-full border-t opacity-50 border-dashed border-zinc-300"></div>
      </div>
    </div>
  )
}
export default TaskDatailComponent
