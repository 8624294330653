"use client";
import React from "react";
import HeaderComponent from "mainComponents/headerComponent";
import ButtonComponent from "mainComponents/button";
import CardComponent from "./components/cardComponent";
import CardsCreatsComponent from "./components/cardsCreatscomponent";
import { useSelector } from "react-redux";
import { postProjectsCreate } from "./api/projects";
import { useNavigate } from "react-router-dom";

export default function Check() {
  const navigate = useNavigate();
  const { projectDetails } = useSelector((state) => state.createProjectState);

  const handleButtonClick = async () => {
    if (
      projectDetails.pname !== "" ||
      projectDetails.pdesc !== "" ||
      projectDetails.pfirm !== ""
    ) {
      await postProjectsCreate(projectDetails);
      navigate("/projects/create/finish");
    }
  };

  return (
    <div className=" w-full dark:bg-dark-100  bg-stone-100 min-h-screen">
      <div className="container mx-auto">
        <HeaderComponent title={"Adım 2: Neredeyse Hazır.."} />
        <div className="dark:hover:border-violet-500 hover:border-red-600">
          <CardComponent
            pname={projectDetails.pname}
            pdesc={projectDetails.pdesc}
          />
        </div>
        <div className="flex justify-center space-x-5">
          <CardsCreatsComponent
            titleColor="text-gray-39"
            title={"BAŞLANGIÇ TARİHİ"}
            text=""
            textColor="text-red-400"
          />
          <CardsCreatsComponent
            titleColor="text-gray-39"
            title={"PROJE SAHİBİ"}
            text=""
            textColor="text-gray-500"
          />
          <CardsCreatsComponent
            titleColor="text-gray-39"
            title={"FİRMA"}
            text=""
            textColor="text-gray-500"
          />
        </div>
        <div className="flex justify-center space-x-2 bg-white w-80 mx-auto p-4 rounded-2xl dark:bg-dark-500 mt-5">
          <ButtonComponent
            id={""}
            href="/projects/create"
            title={"Geri"}
            bgcolor="bg-gray-100 text-black rounded-xl flex h-10 w-20 text-center justify-center dark:bg-dark-300 dark:text-gray-300 hover:bg-stone-200  border-2 dark:border-dark-500 dark:hover:border-violet-500"
          />
          <ButtonComponent
            onClick={() => handleButtonClick()}
            title={"Oluştur"}
            bgcolor="bg-red-600 rounded-xl text-white flex h-10 w-20 text-center justify-center w-44 dark:bg-violet-500 hover:bg-violet-500"
          />
        </div>
      </div>
  
    </div>
  );
}
