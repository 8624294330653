import React from 'react'
import CircularProgressComponent from '../../../../mainComponents/circularProgressComponent'


export default function GorevComponent({ task, priority }) {
  const colors = ['#CDDC39', '#8BC34A', '#2196F3', '#FF9800', '#F44336']
  // const classnameList=["stroke-[#CDDC39]","stroke-[#8BC34A]","stroke-[#2196F3]","stroke-[#FF9800]","stroke-[#F44336]"]
  //indicator:classnameList[priority-1]
  const items = { priority: priority, width: '32px', height: '32px' }
  return (
    <div className="flex flex-row items-center gap-5 my-4">
      <CircularProgressComponent items={items} />
      <p className="text-sm font-semibold text-[#283252] dark:text-[#AAAAB3]">{task}</p>
    </div>
  )
}
