import React from 'react'
import { FaPencilAlt } from 'react-icons/fa'


export default function CardComponent({ pname, pdesc }) {
  return (
    <div className="flex justify-center items-center">
      <div className="flex-col my-4">
        <div className="flex justify-center text-[20px] font-[600] text-gray-39 dark:text-darkWhite-300">Her şeyin iyi olduğundan emin olun</div>
        <div className="flex justify-center text-l font-semibold opacity-50 text-gray-39 dark:text-darkWhite-300">
          Herhangi bir şeyi düzenlemeniz gerekirse önceki adımlara dönebilirsiniz.
        </div>
        <div className="flex justify-center text-xl font-bold text-gray-39 dark:text-darkWhite-300 my-5">{pname?pname:"Proje Adı Belirtilmedi"}</div>
        <div className="flex flex-col ">
          <div className="flex flex-col w-full h-[90px] border-2 rounded-2xl bg-white dark:bg-dark-500  dark:border-dark-500 ">
            <h1 className="flex justify-between text-xs pl-4 pt-4 text-gray-39 font-bold dark:text-gray-400">
              AÇIKLAMA <FaPencilAlt className="mr-4" size={15} />
            </h1>

            <div className="flex w-[625px] pl-4 text-sm pt-2 outline-none border-none opacity-100 border-2 rounded-2xl dark:bg-dark-500 ">
              <p className="opacitiy-40 text-gray-500 dark:text-gray-400 text-ml ">{pdesc?pdesc:"Proje Açıklaması Belirtilmedi"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
