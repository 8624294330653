import React, { useState } from "react";
import ButtonComponent from "../../../../mainComponents/button";
import { RxHamburgerMenu } from "react-icons/rx";

export default function LandingHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="container mx-auto px-4 relative">
      <div className="hidden sm:block lg:pt-6">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="flex flex-col sm:flex-row items-center gap-4 w-full">
            <div className="flex overflow-hidden rounded-full">
              <img
                src="../../assets/penalogo.svg"
                className="h-[60px] max-w-[150px] object-contain"
                alt="Pena Logo"
              />
            </div>
            <ul className="flex flex-wrap justify-center gap-5 ml-4 mt-5 sm:mt-0">
              {["HeyPena", "Özellikler", "Araçlar", "Destek"].map((item) => (
                <li
                  key={item}
                  className="text-md hover:border-b-1 hover:border-red-600 transition-all duration-250 cursor-pointer"
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
           <ButtonComponent
                id=""
                href="/login"
                title="Giriş Yap"
                bgcolor="bg-red-500 py-2 flex justify-center rounded-3xl text-white font-semibold text-sm min-w-[120px]"
              />
        </div>
      </div>
      <div className="sm:hidden flex justify-between items-center mt-3">
        <img
          src="../../assets/penalogo.svg"
          className="h-[60px] max-w-[150px] object-contain"
          alt="Pena Logo"
        />

        {!isMenuOpen && (
          <button
            onClick={() => setIsMenuOpen(true)}
            className="text-gray-500 hover:text-gray-700 z-50"
          >
            <RxHamburgerMenu size={24} />
          </button>
        )}
      </div>

      {/* Sağdan açılan menü */}
      <div
        className={`fixed top-0 right-0 h-full w-64 bg-white shadow-lg transform ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out z-50`}
      >
        <div className="p-5">
          <button
            onClick={() => setIsMenuOpen(false)}
            className="absolute top-5 right-5 text-gray-500 hover:text-gray-700"
          >
            X
          </button>
          <ul className="flex flex-col space-y-4 mt-10">
            {["HeyPena", "Özellikler", "Araçlar", "Destek"].map((item) => (
              <li
                key={item}
                className="text-lg text-gray-700 hover:text-gray-900 cursor-pointer"
              >
                {item}
              </li>
            ))}
            <li className="flex justify-center">
              {" "}
              <ButtonComponent
                id=""
                href="/login"
                title="Giriş Yap"
                bgcolor="bg-red-500 py-2 flex justify-center rounded-3xl text-white font-semibold text-sm min-w-[120px]"
              />
            </li>
          </ul>
        </div>
      </div>

      {/* Arka plan overlay */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsMenuOpen(false)}
        ></div>
      )}
    </div>
  );
}
