import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  projectDetails: {
    pname: '',
    pdesc: '',
    pfirm: '',
    pcustomer: 1,
    pbudget: '200',
    penddate: 1
  }
}

const createProjectState = createSlice({
  name: 'createProjectState',
  initialState,
  reducers: {
    setProjectDetails: (state, action) => {
      state.projectDetails = { ...state.projectDetails, ...action.payload }
    }
  }
})

export const { setProjectDetails } = createProjectState.actions
export default createProjectState.reducer
