import "./App.css";
import * as React from "react";
// import "swiper/css";
// import "swiper/css/navigation";
import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import AppRouter from "./routes";
import { store } from "./store/index.ts";
import { Provider } from "react-redux";
import Loading from "./loading";


// import { ToastContainer } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

function App({ children }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3300);
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="App">
      <BrowserRouter>
        <Provider store={store}>
          <div className="flex flex-col">
            {/* <ToastContainer />  */}
            <AppRouter />
          </div>
        </Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
