import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { useParams } from "react-router-dom"; // useParams'ı import ediyoruz
import Snippet from "mainComponents/snippetComponent";
import {
  getProjectsTeamInvite,
  postProjectsUserSet,
} from "../../../services/projects";
function RequestPopupComponent(props) {
  const [isOpen, setIsOpen] = useState(true);
  const [action, setAction] = useState(1);
  const { id } = useParams(); // URL'den projectId parametresini alıyoruz
  const data = { slug: id };

  const [invites, setInvitesData] = useState([]);
  async function fetchData() {
    const get = await getProjectsTeamInvite(data);
    setInvitesData(get.usersData);
  }
  const handleSubmit = async (userid) => {
    const metadata = {
      slug: data.slug,
      userid,
      action,
    };
    const response = await postProjectsUserSet(metadata);
    fetchData();
    handleClose();
  };

  const handleClose = () => {
    setIsOpen(false);
    props.closePopup(false);
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      // Eğer overlay alanına tıklanırsa kapat
      setIsOpen(false);
      props.closePopup(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {props.show && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleBackgroundClick}
        >
          <div className="bg-white dark:bg-[#3B3B41]  w-[800px] h-[550px] rounded-md shadow-lg">
            <div className="flex items-center justify-between p-4 border-b border-gray-200">
              <h1 className="text-sm font-bold text-gray-700 dark:text-gray-200">
                Kullanıcı İstekleri
              </h1>
              <button onClick={handleClose}>
                <MdClose className="text-xl text-black dark:text-[#78787e]" />
              </button>
            </div>
            <div className="p-4">
              {invites === undefined && 
            <div className="flex flex-col items-center">
              <img
                className="w-40 mx-auto mb-2"
                src="/assets/dashboard/aktifp.svg"
                alt=""
              />
              <p className="text-sm text-gray-400">
                Projenize gelen herhangi bir istek bulunamadı.
              </p>
            </div>}
              <div className="flex items-center justify-center w-full mb-4 bg-gray-200 rounded-md opacity-50"></div>
              <div className="text-center">
                {invites !== undefined &&
                  invites.map((invites, index) => (
                    <div
                      key={index}
                      className="flex items-center dark:text-gray-200 dark:bg-[#4a4a50] justify-between p-4 mb-4 bg-gray-100 rounded-md"
                    >
                      <div>
                        <h1 className="font-bold">{invites[0]}</h1>
                      </div>
                      <button
                        className="btn"
                        onClick={() => handleSubmit(invites[6], setAction(1))}
                      >
                        Kabul et
                      </button>
                    </div>
                  ))}
              </div>{" "}
            </div>
            <div className="p-4 border-t border-gray-200">
              <div className="p-6">
                <h1 className="mb-4 font-bold">
                  Ekip arkadaşı davet etmek için kopyalayın!
                </h1>
                <Snippet text={`${data.slug}`} />
              </div>
              <div className="flex justify-end">
                <button className="text-gray-700">Çıkış</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RequestPopupComponent;
