import axiosClient from '../libs/axios'
export const postMytask = async (data) => {
  const response = await axiosClient.post('/mytask', data)
  return response.data
}
export const getMytaskList = async () => {
  const response = await axiosClient.get('/mytask/list')
  return response.data
}
export const getMytaskLast = async () => {
  const response = await axiosClient.get('/mytask/last')
  return response.data
}
export const postMytaskSet = async (data) => {
  const response = await axiosClient.post('/mytask/set', data)
  return response.data
}
export const postMytaskFinish = async (data) => {
  const response = await axiosClient.post('/mytask/finish', data)
  return response.data
}
export const postMytaskDelete = async (data) => {
  const response = await axiosClient.post('/mytask/delete', data)
  return response.data
}
