import React, { useState } from 'react';

const Snippet = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); 
    }).catch(err => {
      console.error('Kopyalama hatası:', err);
    });

  };

  

  return (
    <div 
      className="relative inline-block p-4 border border-gray-300 rounded-md cursor-pointer hover:bg-gray-100"
      onClick={handleCopy}
    >
      <span>{text}</span>
      {copied && (
        <span className="ml-2 font-bold text-green-500">
          Kopyalandı!
        </span>
      )}

    </div>
  );
};

export default Snippet;
