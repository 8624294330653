
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activePanel: [0,0]
}

const popupState = createSlice({
  name: 'popupState',
  initialState,
  reducers: {
    setActivePanel: (state, action) => {
      state.activePanel = action.payload
    }
  }
})

export const { setActivePanel, resetActivePanel } = popupState.actions
export default popupState.reducer
