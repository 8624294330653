import HeaderComponent from "mainComponents/headerComponent";
import MenuBarComponent from "../components/MenuBarComponent";
import React from "react";
import { PiCircleBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { FaCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { getProjectsTimeline, getProjectsTeam } from "../api/projects";
import { useState, useEffect } from "react";
export default function ProjectsTimeline() {
  const { id } = useParams();
  const [timelineData, setTimelineData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [selectedPuid, setSelectedPuid] = useState([]);

  async function fetchTeamData() {
    const response = await getProjectsTeam({ slug: id });
    setTeamData(response.usersData);
  }
  async function fetchTimelineData() {
    const response = await getProjectsTimeline({ slug: id });
    setTimelineData(response.timelineData);

    setSelectedPuid(response.timelineData[0]?.tuid);
  }
  const getUsernameById = (userId) => {
    const user = teamData.find((user) => user.id === userId);
    return user ? user.name : "Unknown User";
  };
  useEffect(() => {
    fetchTimelineData();
    fetchTeamData();
  }, []);
  return (
    <div className=" w-full min-h-screen dark:bg-dark-100  bg-stone-100 transition duration-300 ">
      <div className="container mx-auto">
        <HeaderComponent title={"Proje Süreci"} />
        <MenuBarComponent title={"timeline"} />
        <div className="flex w-full mt-5">
          <ul className="timeline timeline-vertical w-full -ml-[100px]  ">
            {timelineData.map((timeline, index) => {
              const date = timeline.date[0].split("T");
              return (
                <li key={index} className="grid grid-cols-9 justify-center">
                  <div className="timeline-start  dark:text-gray-500 -mr-10">{date[0]}</div>
                  <div className="timeline-middle">
                    <div className="text-blue-600">
                      <PiCircleBold />
                    </div>
                  </div>
                  <hr className="bg-gray-200 dark:bg-gray-500 " />{" "}
                  <div className="col-span-7 -ml-10 timeline-end dark:bg-dark-300 dark:border-gray-500 bg-white border-gray-200 timeline-box rounded-md flex justify-between items-center min-h-20 gap-3 w-[1125px]">
                    <Link key={index} to="inside" state={timeline} className="">
                      <div className="flex items-center gap-3">
                        <div className="text-red-600 ">
                          <PiCircleBold size={20} />
                        </div>
                        <div className="avatar"></div>
                        <div className="flex flex-col">
                          <div>
                            <span className="font-bold text-black dark:text-gray-400">
                              {getUsernameById(timeline.userId)} {"  "}
                            </span>
                            <span className=" text-gray-500">
                              {timeline.tsubject} görevini
                            </span>
                            <span className="text-purple-500">
                              {" "}
                              güncelledi! 
                            </span>
                          </div>
                          <div className="text-xs text-gray-500">{date[1]}</div>
                        </div>
                      </div>
                      {/* list */}{" "}
                    </Link>
                  </div>{" "}
                  <hr className="bg-gray-200 dark:bg-gray-500 " />{" "}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
