import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  taskChanged: false,
  chatStatus: false,
  projectStatus: false,
  proNotifyProc: '',
  proNotifyData: [],
  proNotifyNewTaskList: {},
  proNotifyCurTaskList: [],
  bellNotify: false,
  SearchPanel: false
}

const notifies = createSlice({
  name: 'notifies',
  initialState,
  reducers: {
    setChatNotify: (state, action) => {
      state.chatStatus = action.payload
    },
    setTaskChanged: (state, action) => {
      state.taskChanged = action.payload
    },
    setProjectNotify: (state, action) => {
      state.projectStatus = action.payload
    },
    setProNotifyProc: (state, action) => {
      state.proNotifyProc = action.payload
    },
    setProNotifyData: (state, action) => {
      state.proNotifyData = action.payload
    },
    setProNewTaskList: (state, action) => {
      if(action.payload[0] == 0){
        state.proNotifyNewTaskList = { ...state.proNotifyNewTaskList, ...action.payload[1] }
      }
      else if(action.payload[0] == 1){
        state.proNotifyNewTaskList[action.payload[2]] = { ...state.proNotifyNewTaskList[action.payload[2]], ...action.payload[1] }
      }
      else if(action.payload[0] == 2){
        delete state.proNotifyNewTaskList[action.payload[2]][action.payload[1]]
       }      
    },
    setSearchPanel: (state, action) => {
      state.SearchPanel = action.payload
    },
    setBellNotify: (state, action) => {
      state.bellNotify = action.payload
    }
  }
})

export const { setChatNotify, setProjectNotify, setSearchPanel, setBellNotify, setTaskChanged, setProNotifyData, setProNotifyProc,setProNewTaskList } = notifies.actions
export default notifies.reducer