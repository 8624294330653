import React from 'react'

const CardComponent = ({ title, titleColor, textColor, text }) => {
  return (
    <div className="bg-white w-48 h-40 text-sm pt-2 outline-none border-1 opacity-100 border-2 rounded-2xl dark:bg-dark-500">
      <div className="flex flex-col text-center align-middle ">
        <h1 className={`text-xs font-bold dark:text-gray-400 ${titleColor}`}>{title}</h1>
        <div className={`text-lg font-medium mt-10 ${textColor}`}>{text}</div>
      </div>
    </div>
  )
}

export default CardComponent
